var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.show
    ? _c(
        "div",
        { staticClass: "user-form", attrs: { "data-testid": "UserForm" } },
        [
          _c(
            "page-title-area",
            [
              _c("entity-header", { attrs: { icon: "user" } }, [
                _vm._v(
                  "\n      " +
                    _vm._s(
                      _vm.newRecord
                        ? _vm.$t("label.create_account")
                        : _vm.user.name
                    ) +
                    "\n    "
                ),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "user-form__content" }, [
            _c(
              "div",
              { staticClass: "user-form__form my-3" },
              [
                _c(
                  "v-form",
                  {
                    ref: "form",
                    model: {
                      value: _vm.valid,
                      callback: function ($$v) {
                        _vm.valid = $$v
                      },
                      expression: "valid",
                    },
                  },
                  [
                    _c("form-group", {
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "label",
                            fn: function () {
                              return [
                                _c("span", [
                                  _vm._v(_vm._s(_vm.$t("field.organization"))),
                                ]),
                              ]
                            },
                            proxy: true,
                          },
                          {
                            key: "input",
                            fn: function () {
                              return [
                                _vm.admin
                                  ? _c("select-field", {
                                      attrs: {
                                        items: _vm.organization_options,
                                        edit: _vm.state.editing,
                                        "data-testid": "owner_id",
                                      },
                                      model: {
                                        value: _vm.model.owner_id,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.model, "owner_id", $$v)
                                        },
                                        expression: "model.owner_id",
                                      },
                                    })
                                  : _c("span", [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(_vm.organization.name) +
                                          "\n            "
                                      ),
                                    ]),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        false,
                        1893156388
                      ),
                    }),
                    _vm._v(" "),
                    _c("form-group", {
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "label",
                            fn: function () {
                              return [
                                _c("span", [
                                  _vm._v(_vm._s(_vm.$t("field.name"))),
                                ]),
                              ]
                            },
                            proxy: true,
                          },
                          {
                            key: "input",
                            fn: function () {
                              return [
                                _c("text-field", {
                                  attrs: {
                                    edit: _vm.state.editing,
                                    rules: [_vm.required(_vm.$t("field.name"))],
                                    "data-testid": "name",
                                  },
                                  model: {
                                    value: _vm.model.name,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.model, "name", $$v)
                                    },
                                    expression: "model.name",
                                  },
                                }),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        false,
                        1871170257
                      ),
                    }),
                    _vm._v(" "),
                    _c("form-group", {
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "label",
                            fn: function () {
                              return [
                                _c("span", [
                                  _vm._v(_vm._s(_vm.$t("field.email"))),
                                ]),
                              ]
                            },
                            proxy: true,
                          },
                          {
                            key: "input",
                            fn: function () {
                              return [
                                _c("text-field", {
                                  attrs: {
                                    type: "email",
                                    edit: _vm.state.editing,
                                    rules: [
                                      _vm.required(_vm.$t("field.email")),
                                      _vm.email(_vm.$t("field.email")),
                                    ],
                                    "data-testid": "email",
                                  },
                                  model: {
                                    value: _vm.model.email,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.model, "email", $$v)
                                    },
                                    expression: "model.email",
                                  },
                                }),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        false,
                        1475622979
                      ),
                    }),
                    _vm._v(" "),
                    _c("form-group", {
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "label",
                            fn: function () {
                              return [
                                _c("span", [
                                  _vm._v(_vm._s(_vm.$t("field.locale"))),
                                ]),
                              ]
                            },
                            proxy: true,
                          },
                          {
                            key: "input",
                            fn: function () {
                              return [
                                _c("select-field", {
                                  attrs: {
                                    items: _vm.locale_options,
                                    edit: _vm.state.editing,
                                    rules: [
                                      _vm.required(_vm.$t("field.locale")),
                                    ],
                                    "data-testid": "locale",
                                  },
                                  model: {
                                    value: _vm.model.locale,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.model, "locale", $$v)
                                    },
                                    expression: "model.locale",
                                  },
                                }),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        false,
                        2921213971
                      ),
                    }),
                    _vm._v(" "),
                    _vm.admin
                      ? [
                          _c("form-group", {
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "label",
                                  fn: function () {
                                    return [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("field.allowed_sensor_types")
                                          )
                                        ),
                                      ]),
                                    ]
                                  },
                                  proxy: true,
                                },
                                {
                                  key: "input",
                                  fn: function () {
                                    return [
                                      _c(
                                        "div",
                                        [
                                          _vm._l(
                                            _vm.sensorTypesForUser,
                                            function (t) {
                                              return [
                                                _c("checkbox", {
                                                  key: t + "-checkbox",
                                                  attrs: {
                                                    label:
                                                      _vm.SENSOR_TYPE_NAMES[t],
                                                    value: t,
                                                    edit: _vm.state.editing,
                                                    "data-testid":
                                                      "allowed_sensor_type_" +
                                                      t,
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.model
                                                        .allowed_sensor_types,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.model,
                                                        "allowed_sensor_types",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "model.allowed_sensor_types",
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c("br", { key: t + "-br" }),
                                              ]
                                            }
                                          ),
                                        ],
                                        2
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              false,
                              3801039585
                            ),
                          }),
                          _vm._v(" "),
                          _vm.stationEnabled
                            ? [
                                _c("form-group", {
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "label",
                                        fn: function () {
                                          return [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(_vm.$t("field.plan"))
                                              ),
                                            ]),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                      {
                                        key: "input",
                                        fn: function () {
                                          return [
                                            _c(
                                              "div",
                                              [
                                                _c("select-field", {
                                                  attrs: {
                                                    rules: [
                                                      _vm.required(
                                                        _vm.$t("field.plan")
                                                      ),
                                                    ],
                                                    items: _vm.planOptions,
                                                    edit: _vm.state.editing,
                                                    "data-testid": "plan",
                                                  },
                                                  model: {
                                                    value: _vm.model.next_plan,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.model,
                                                        "next_plan",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "model.next_plan",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _vm.planChangedAlertMessage
                                              ? _c(
                                                  "span",
                                                  {
                                                    attrs: {
                                                      "data-testid":
                                                        "plan-alert",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                  " +
                                                        _vm._s(
                                                          "" +
                                                            _vm.planChangedAlertMessage
                                                        ) +
                                                        "\n                "
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ],
                                    null,
                                    false,
                                    1674202863
                                  ),
                                }),
                              ]
                            : _vm._e(),
                        ]
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.mirakunDeviceEnabled
                      ? _c("form-group", {
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "label",
                                fn: function () {
                                  return [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(_vm.$t("field.mirakun_user_id"))
                                      ),
                                    ]),
                                  ]
                                },
                                proxy: true,
                              },
                              {
                                key: "input",
                                fn: function () {
                                  return [
                                    _c("text-field", {
                                      attrs: {
                                        edit: _vm.state.editing,
                                        "data-testid": "mirakun_user_id",
                                      },
                                      model: {
                                        value: _vm.model.mirakun_user_id,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.model,
                                            "mirakun_user_id",
                                            $$v
                                          )
                                        },
                                        expression: "model.mirakun_user_id",
                                      },
                                    }),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            false,
                            1748275986
                          ),
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.state.editing
                      ? _c("form-group", {
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "label",
                                fn: function () {
                                  return [
                                    _c("span", [
                                      _vm._v(_vm._s(_vm.$t("field.password"))),
                                    ]),
                                  ]
                                },
                                proxy: true,
                              },
                              {
                                key: "input",
                                fn: function () {
                                  return [
                                    _c("text-field", {
                                      attrs: {
                                        type: "password",
                                        edit: _vm.state.editing,
                                        rules: [
                                          _vm.rules.password_required,
                                          _vm.password_length(),
                                          _vm.password_characters(),
                                        ],
                                        "data-testid": "password",
                                      },
                                      model: {
                                        value: _vm.model.password,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.model, "password", $$v)
                                        },
                                        expression: "model.password",
                                      },
                                    }),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            false,
                            4239899494
                          ),
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.state.editing
                      ? _c("form-group", {
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "label",
                                fn: function () {
                                  return [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(_vm.$t("field.password_confirm"))
                                      ),
                                    ]),
                                  ]
                                },
                                proxy: true,
                              },
                              {
                                key: "input",
                                fn: function () {
                                  return [
                                    _c("text-field", {
                                      attrs: {
                                        type: "password",
                                        edit: _vm.state.editing,
                                        rules: [_vm.match_password()],
                                        "data-testid": "password_confirm",
                                      },
                                      model: {
                                        value: _vm.model.password_confirm,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.model,
                                            "password_confirm",
                                            $$v
                                          )
                                        },
                                        expression: "model.password_confirm",
                                      },
                                    }),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            false,
                            1750866389
                          ),
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.admin
                      ? _c("form-group", {
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "label",
                                fn: function () {
                                  return [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(_vm.$t("field.invited_by"))
                                      ),
                                    ]),
                                  ]
                                },
                                proxy: true,
                              },
                              {
                                key: "input",
                                fn: function () {
                                  return [
                                    _vm._v(
                                      "\n            " +
                                        _vm._s(
                                          _vm._f("invitedBy")(
                                            _vm.user.invited_by
                                          )
                                        ) +
                                        "\n          "
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            false,
                            1364394767
                          ),
                        })
                      : _vm._e(),
                  ],
                  2
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "user-form__btn-group" },
              [
                _vm.state.editing
                  ? [
                      _c(
                        "round-button",
                        {
                          staticClass: "user-form__btn my-3",
                          attrs: {
                            icon: "fa-check-circle",
                            fullWidth: "",
                            title: "save",
                            "data-testid": "save",
                          },
                          on: { click: _vm.onSave },
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("button.save")) +
                              "\n        "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "round-button",
                        {
                          staticClass: "user-form__btn my-3",
                          attrs: {
                            icon: "fa-arrow-circle-left",
                            fullWidth: "",
                            "data-testid": "cancel",
                          },
                          on: { click: _vm.onCancel },
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("button.cancel")) +
                              "\n        "
                          ),
                        ]
                      ),
                    ]
                  : [
                      _c(
                        "round-button",
                        {
                          staticClass: "user-form__btn my-3",
                          attrs: {
                            icon: "fa-pencil-square-o",
                            fullWidth: "",
                            "data-testid": "edit",
                          },
                          on: { click: _vm.onEdit },
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("button.edit")) +
                              "\n        "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _vm.admin
                        ? [
                            _c(
                              "round-button",
                              {
                                staticClass: "user-form__btn my-3",
                                attrs: {
                                  icon: _vm.model.activated
                                    ? "fa-minus-circle"
                                    : "fa-plus-circle",
                                  fullWidth: "",
                                  "data-testid": "toggle-activated",
                                },
                                on: { click: _vm.onToggleActivated },
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(
                                      _vm.model.activated
                                        ? _vm.$t("button.deactivate")
                                        : _vm.$t("button.activate")
                                    ) +
                                    "\n          "
                                ),
                              ]
                            ),
                          ]
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.admin && _vm.model.blocked
                        ? [
                            _c(
                              "round-button",
                              {
                                staticClass: "user-form__btn my-3",
                                attrs: {
                                  icon: "fa-plus-circle",
                                  fullWidth: "",
                                  "data-testid": "unblock",
                                },
                                on: { click: _vm.onUnblock },
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(_vm.$t("button.unblock")) +
                                    "\n          "
                                ),
                              ]
                            ),
                          ]
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.admin &&
                      !_vm.model.blocked &&
                      _vm.model.activated &&
                      !_vm.isAdminUserDisplayed
                        ? [
                            _c(
                              "round-button",
                              {
                                staticClass: "user-form__btn my-3",
                                attrs: {
                                  fullWidth: "",
                                  "data-testid": "login-as-user",
                                },
                                on: { click: _vm.onLoginAsUser },
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(_vm.$t("button.login_as_user")) +
                                    "\n          "
                                ),
                              ]
                            ),
                          ]
                        : _vm._e(),
                    ],
              ],
              2
            ),
          ]),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }