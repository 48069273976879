<template>
  <div class="threshold-alert-status" data-testid="threshold-alert-status">
    <template v-if="showAlert">
      <div class="d-flex align-center flex-grow-1">
        <div class="flex-grow-1 mr-3 text-nowrap">
          {{ $t('untilAlert', { value: insectsUntilSumAlert }) }}
        </div>
        <v-progress-linear
          :color="insectsUntilSumAlert > 0 ? 'primary' : 'warning'"
          :value="event.count * 100 / event.sensor_threshold"
          height="15"
        />
      </div>
      <div class="d-flex align-center my-1" data-testid="threshold-alert-status__message">
        <div
          class="threshold-alert-status__led mr-2"
          :class="`threshold-alert-status__led--${alertStatus}`"
        />
        {{alertMessage}}
      </div>
    </template>
    <div class="d-flex flex-wrap my-1">
      <div>{{ $t('sensor_threshold') }} <span class="mx-1">:</span></div>
      <div>
        {{ $t('summary') }} = {{ event.sensor_threshold }} / {{ $t('increase') }} = {{ event.sensor_increase_threshold }}
      </div>
    </div>
    <template v-if="updatableThreshold">
      <div class="mt-2">
        <v-btn
          depressed
          rounded
          small
          class="px-7"
          color="white"
          @click.stop="openDialog"
          v-if="editable"
        >
          {{ $t('button.update_threshold') }}
        </v-btn>
      </div>
      <v-dialog v-model="dialog" max-width="300" content-class="threshold-alert-status__dialog">
        <v-form v-model="valid" v-if="dialog">
          <v-text-field
            v-model="model.threshold"
            type="number"
            autofocus
            :label="$t('threshold')"
            :rules="rules['threshold']"
          />
          <v-text-field
            v-model="model.increase_threshold"
            type="number"
            :label="$t('increase_threshold')"
            :rules="rules['increase_threshold']"
          />
        </v-form>
        <div class="d-flex justify-end">
          <v-btn
            color="primary"
            class="ma-0 "
            @click="submit"
            :disabled="!valid || !isThresholdChanged"
          >
            {{ $t('button.update') }}
          </v-btn>
        </div>
      </v-dialog>
    </template>
  </div>
</template>

<i18n lang="yaml">
ja:
  untilAlert: "アラートが出るまでの頭数: {value}"
  threshold: "しきい値"
  increase_threshold: "増加のしきい値"
  summary: "合計"
  increase: "増加量"
  sensor_threshold: アップロード時のしきい値
  over_both_threshold: 合計・増加アラート発生中
  over_summary_threshold: 合計アラート発生中
  over_increase_threshold: 増加アラート発生中
  button:
    update_threshold: しきい値再設定
    update: 更新
  msg:
    update_success: しきい値を更新しました
    update_error: しきい値の更新中にエラーが発生しました

en:
  untilAlert: "{value} more insects until alert."
  threshold: "Threshold"
  increase_threshold: "Increase threshold"
  summary: "Summary"
  increase: "Increase"
  sensor_threshold: "Threshold when photo was uploaded"
  over_both_threshold: Over both summary and increase threshold
  over_summary_threshold: Over summary threshold
  over_increase_threshold: Over increase threshold
  button:
    update_threshold: Update threshold
    update: Update
  msg:
    update_success: 'Threshold was successfully updated.'
    update_error: 'An error occurred while updating the threshold.'
</i18n>

<script>
import Notifications from '@/mixins/notifications';
import Validations from '@/components/Form/mixins/form-validations';

export default {
  name: 'threshold-alert-status',
  props: {
    sensor: {
      type: Object,
      default() {
        return {};
      },
    },
    editable: Boolean,
    event: {
      type: Object,
      default() {
        return {};
      },
    },
    showAlert: {
      type: Boolean,
      default: false,
    },
    updatableThreshold: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [Validations, Notifications],
  computed: {
    insectsUntilSumAlert() {
      const { count, sensor_threshold: threshold } = this.event;
      if (count > threshold) {
        return 0;
      }
      return threshold - count;
    },
    insectsUntilDiffAlert() {
      const {
        count_difference: count,
        sensor_increase_threshold: threshold,
      } = this.event;
      if (count > threshold) {
        return 0;
      }
      return threshold - count;
    },
    alertStatus() {
      if (this.insectsUntilSumAlert <= 0 && this.insectsUntilDiffAlert <= 0) {
        return 'over_both_threshold';
      }
      if (this.insectsUntilSumAlert <= 0) {
        return 'over_summary_threshold';
      }
      if (this.insectsUntilDiffAlert <= 0) {
        return 'over_increase_threshold';
      }
      return 'normal';
    },
    alertMessage() {
      switch (this.alertStatus) {
        case 'over_both_threshold':
          return this.$t('over_both_threshold');
        case 'over_summary_threshold':
          return this.$t('over_summary_threshold');
        case 'over_increase_threshold':
          return this.$t('over_increase_threshold');
        default:
          return '';
      }
    },
    isThresholdChanged() {
      return this.model.threshold !== this.event.sensor_threshold ||
        this.model.increase_threshold !== this.event.sensor_increase_threshold;
    },
  },
  data() {
    return {
      dialog: false,
      model: {
        threshold: 0,
        increase_threshold: 0,
      },
      valid: false,
      rules: {
        threshold: [
          this.required(this.$t('threshold')),
          this.positive(this.$t('threshold')),
        ],
        increase_threshold: [
          this.required(this.$t('increase_threshold')),
          this.positive(this.$t('increase_threshold')),
        ],
      },
    };
  },
  methods: {
    openDialog() {
      this.model = {
        threshold: this.event.sensor_threshold,
        increase_threshold: this.event.sensor_increase_threshold,
      };
      this.dialog = true;
    },
    submit: async function () {
      try {
        const params = {
          id: this.sensor.id,
          data: {
            threshold: Number(this.model.threshold),
            increase_threshold: Number(this.model.increase_threshold),
          },
        };
        await this.$store.dispatch('requestUpdateSensor', params);
        this.notifySuccess('msg.update_success');
        this.dialog = false;
      } catch (error) {
        this.notifyError('msg.update_error');
      }
    },
  },
};
</script>

<style scoped lang="sass">
@import 'vuetify/src/styles/styles.sass'

.threshold-alert-status
  &__led
    height: 50px
    width: 50px
    background-image: url(../../../assets/logo-no.png)
    background-size: cover
    background-repeat: no-repeat
    background-position: center

    &--normal
      background-image: url(../../../assets/logo-green.png)

    &--over_both_threshold,
    &--over_summary_threshold,
    &--over_increase_threshold
      background-image: url(../../../assets/logo-red.png)
</style>

<style lang="sass">
.threshold-alert-status
  &__dialog
    background-color: #fff
    padding: 1rem
    width: 300px
</style>
