<template>
  <card-dialog
    v-model="dialogVisible"
    :width="1200"
    persistent
    :transition="false"
  >
    <template #activator>
      <round-button icon="insert_chart" @click="dialogHandle">
        {{ $t('button_label') }}
      </round-button>
    </template>
    <select-box
      class="insect-select-box"
      itemMenuClass="insect-items"
      :label="$t('insects')"
      :items="insects"
      :key="i18n_locale"
      v-model="insect"
    />
    <!-- :key="i18n_locale"→Localeが変わったら再レンダリングさせる -->
    <bar-chart
      :data="chartData"
      :options="chartOptions"
      :styles="{height: '450px'}"
      v-if="graphVisible"
    />
    <template #actions>
      <dialog-action
        class="close-button"
        @click="close"
      >
        {{ $t('close') }}
      </dialog-action>
    </template>
  </card-dialog>
</template>

<i18n lang="yaml">
ja:
  button_label: 'グラフを出力'
  close: 閉じる
  insects: 虫の種類
  unselected: 全種合計
  error:
    selectData: 'グラフを表示するデータを選択して下さい'
en:
  button_label: 'Display Graph'
  close: close
  insects: insects
  unselected: Total of all species
  error:
    selectData: 'Select the data to display the graph'
</i18n>

<script>
import InsectTypes from '@/mixins/insectTypes';
import Notifications from '@/mixins/notifications';
import BarChart from '@/components/atoms/BarChart';
import CardDialog from '@/components/atoms/CardDialog';
import DialogAction from '@/components/atoms/DialogAction';
import RoundButton from '@/components/atoms/RoundButton';
import SelectBox from '@/components/atoms/SelectBox';

export default {
  name: 'picked-event-chart-button',
  mixins: [InsectTypes, Notifications],
  props: {
    value: {
      type: Boolean,
    },
    events: {
      type: Array,
      required: true,
    },
  },
  components: {
    BarChart,
    CardDialog,
    DialogAction,
    RoundButton,
    SelectBox,
  },
  data() {
    return {
      colors: {
        0: '#a5a5a5',
        1: '#ed7d31',
        2: '#4472c4',
      },
      insect: null,
      dialogVisible: false,
      graphVisible: false,
    };
  },
  computed: {
    monthLabels() {
      if (this.$i18n.locale === 'ja') {
        return [
          '1月', '2月', '3月', '4月', '5月', '6月',
          '7月', '8月', '9月', '10月', '11月', '12月',
        ];
      }
      return [
        'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
        'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec',
      ];
    },
    insects() {
      return [{ text: this.$t('unselected'), value: null }, ...this.INSECT_TYPES_FOR_OPTION];
    },
    years() {
      return Object.keys(this.datasetsByYear).sort();
    },
    datasetsByYear() {
      // { 2020: [null, null, null, 22, ...], 2021: [...] }
      const result = {};

      this.events.forEach((event) => {
        const year = event.month.split('-')[0];
        if (!result[year]) {
          result[year] = new Array(12);
        }
        const month = Number(event.month.split('-')[1]) - 1;
        if (!result[year][month]) {
          result[year][month] = 0;
        }
        const count = this.countFor(event);
        if (typeof count === 'number') {
          result[year][month] += this.countFor(event);
        }
      });

      return result;
    },
    yMax() {
      const maxCount = this._.max(
        Object.values(this.datasetsByYear).map(counts => this._.max(counts)),
      );

      if (maxCount < 100) {
        return this.roundToNext(maxCount, 10);
      }
      if (maxCount < 1000) {
        return this.roundToNext(maxCount, 50);
      }
      return this.roundToNext(maxCount, 100);
    },
    dataSets() {
      return this.years.map((year, index) => (
        {
          label: year,
          backgroundColor: this.colors[index],
          data: this.datasetsByYear[year],
        }
      ));
    },
    chartData() {
      return {
        labels: this.monthLabels,
        datasets: this.dataSets,
      };
    },
    chartOptions() {
      return {
        plugins: {
          datalabels: {
            display: true,
            font: {
              size: 14,
            },
            anchor: 'end',
            align: 'end',
          },
          legend: {
            position: 'bottom',
          },
        },
        scales: {
          yAxis: {
            beginAtZero: true,
            suggestedMax: this.yMax,
          },
        },
        layout: {
          padding: {
            top: 50,
          },
        },
      };
    },
  },
  methods: {
    countFor(event) {
      if (!this.insect) {
        return event.insectCount;
      }

      return event.insect_counts[this.insect];
    },
    close() {
      this.dialogVisible = false;
      this.graphVisible = false;
    },
    roundToNext(value, increment) {
      return (Math.ceil(value / increment) + 1) * increment;
    },
    dialogHandle() {
      if (Object.keys(this.events).length === 0) {
        this.notifyError('error.selectData');
        this.dialogVisible = false;
      } else {
        this.dialogVisible = true;
      }
    },
  },
  watch: {
    dialogVisible(value) {
      if (value) {
        // Show graph after dialog is properly visible so the bar animation will be at the correct
        // position
        setTimeout(() => {
          this.graphVisible = true;
        }, 100);
      }
    },
  },
};
</script>

<style scoped lang="sass">
@import 'vuetify/src/styles/styles.sass'
.insect-select-box
  display: block
  margin: 0 0 0 auto
  padding: 20px
  width: 20%
</style>

<style lang="sass">
  .insect-items
    z-index: 300 !important
</style>
