<template>
  <div class="event-detail-header">
    <span class="event-detail-header__date">
      <span>{{timestamp.date}}</span><span class="ml-3">{{timestamp.time}}</span>
    </span>
    <span class="event-detail-header__count" v-if="count">{{count}}</span>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  name: 'event-detail-header',
  props: ['event'],
  computed: {
    count() {
      return this.event.count;
    },
    timestamp() {
      const timestamp = moment.unix(this.event.timestamp);
      return { date: timestamp.format('YYYY.M.D'), time: timestamp.format('H:mm') };
    },
  },
};
</script>

<style scoped lang="sass">
@import 'vuetify/src/styles/styles.sass'

.event-detail-header
  background-color: map-get($colors, 'grey', 'darken-2')
  color: white
  font-size: 13px
  padding: 0 $spacer
  display: flex
  align-items: center

  &__date
    flex-grow: 1
</style>
