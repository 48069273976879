<template>
  <card-dialog
    :value="value"
    @input="val => $emit('input', val)"
    :width="700"
    :title="$t('label.edit')"
    :persistent="confirmVisible"
  >
    <template v-if="event !== null">
      <v-row class="d-flex flex-wrap">
        <v-col class="d-flex align-center justify-center" xs12 sm5>
          <loading-image :src="event.attachments.tab.original" />
        </v-col>
        <v-col xs12 sm7>
          <v-form v-model="valid" ref="form">
            <form-field :label="$t('field.place')">
              <autocomplete-text-field
                allow-new-values
                :label="$t('field.place')"
                :items="placeItems"
                :loading="placesLoading"
                data-testid="place"
                v-model="form.placeName"
              />
            </form-field>
            <form-field :label="$t('field.trap_no')">
              <autocomplete-text-field
                allow-new-values
                :label="$t('field.trap_no')"
                :items="trapNoItems"
                data-testid="trap_no"
                v-model="form.trap_no"
              />
            </form-field>
            <form-field :label="$t('field.installed_at')">
              <date-field
                v-model="form.installed_at"
                data-testid="installed_at"
                :rules="[validateDates]"
              />
            </form-field>
            <form-field :label="$t('field.collected_at')">
              <date-field
                v-model="form.collected_at"
                data-testid="collected_at"
                :rules="[validateDates]"
              />
            </form-field>
            <form-field :label="$t('field.month')">
              <month-field
                v-model="form.month"
                data-testid="month"
              />
            </form-field>
          </v-form>
        </v-col>
      </v-row>
    </template>
    <template #actions>
      <dialog-action @click="close">{{ $t('button.cancel') }}</dialog-action>
      <dialog-action
       @click.stop="confirmVisible = true;"
       :disabled="anyFieldEmpty || !valid"
      >
        {{ $t('button.save') }}
      </dialog-action>
    </template>
    <card-dialog v-model="confirmVisible" width="auto">
      <div class="pt-6">{{ $t('messages.confirm_save') }}</div>
      <template #actions>
        <dialog-action @click.stop="confirmVisible = false;" :disabled="saving">
          {{ $t('button.no') }}
        </dialog-action>
        <dialog-action @click.stop="save" :disabled="saving">
          <template v-if="saving">{{ $t('button.saving') }}</template>
          <template v-else>{{ $t('button.yes') }}</template>
        </dialog-action>
      </template>
    </card-dialog>
  </card-dialog>
</template>

<i18n lang="yaml">
ja:
  label:
    edit: '編集'
  button:
    cancel: 'キャンセル'
    save: '保存'
    saving: '保存中...'
    yes: 'はい'
    no: 'いいえ'
  field:
    place: '設置場所'
    trap_no: 'トラップNo'
    installed_at: '設置日'
    collected_at: '回収日'
    month: '月'
  messages:
    invalid_dates: '「回収日」は「設置日」より過去の日付に設定できません。'
    confirm_save: '変更を保存しますか？'
en:
  label:
    edit: 'Edit'
  button:
    cancel: 'Cancel'
    save: 'Save'
    saving: 'Saving...'
    yes: 'Yes'
    no: 'No'
  field:
    place: 'Place'
    trap_no: 'Trap No'
    installed_at: 'Installed at'
    collected_at: 'Collected at'
    month: 'Month'
  messages:
    invalid_dates: "'Collected At' cannot be before 'Installed At'"
    confirm_save: 'Do you want to save the changes?'
</i18n>

<script>
import moment from 'moment';
import AutocompleteTextField from '@/components/atoms/AutocompleteTextField';
import CardDialog from '@/components/atoms/CardDialog';
import DialogAction from '@/components/atoms/DialogAction';
import FormField from '@/components/atoms/FormField';
import LoadingImage from '@/components/LoadingImage';
import DateField from '@/components/molecules/DateField';
import MonthField from '@/components/molecules/MonthField';

export default {
  name: 'station-event-editor',
  props: {
    value: Boolean,
    event: Object,
    places: Array,
    place: Object,
  },
  components: {
    AutocompleteTextField,
    CardDialog,
    DateField,
    DialogAction,
    FormField,
    LoadingImage,
    MonthField,
  },
  computed: {
    anyFieldEmpty() {
      return this._.some(this.form, this._.isEmpty);
    },
    placeItems() {
      return this.loadedPlaces.map(place => ({
        text: place.name,
        value: place.name,
      }));
    },
    selectedPlace() {
      return this.loadedPlaces.find(place => place.name === this.form.placeName);
    },
    trapNoItems() {
      return this.selectedPlace ? this.selectedPlace.trap_nos : [];
    },
    placesLoading() {
      return this.places === null;
    },
    loadedPlaces() {
      return this.places || [this.place];
    },
  },
  data() {
    return {
      form: {
        placeName: '',
        trap_no: '',
        installed_at: '',
        collected_at: '',
        month: '',
      },
      valid: false,
      confirmVisible: false,
      saving: false,
    };
  },
  inject: ['updateStationPlaceEvent'],
  methods: {
    close() {
      this.$emit('input', false);
    },
    save: async function () {
      try {
        this.saving = true;
        const attributes = {};
        if (!this.selectedPlace || this.selectedPlace.id !== this.event.place_id) {
          attributes.place = this.form.placeName;
        }
        ['trap_no', 'installed_at', 'collected_at', 'month'].forEach(attribute => {
          if (this.form[attribute] !== this.event[attribute]) {
            attributes[attribute] = this.form[attribute];
          }
        });
        const updated = await this.updateStationPlaceEvent(this.event.id, attributes);
        this.$emit('update', updated);
        this.confirmVisible = false;
        this.close();
      } finally {
        this.saving = false;
      }
    },
    validateDates() {
      // do not validate unless both dates are set
      if (this._.isEmpty(this.form.collected_at) || this._.isEmpty(this.form.installed_at)) {
        return true;
      }
      return moment(this.form.collected_at).isSameOrAfter(this.form.installed_at) || this.$t('messages.invalid_dates');
    },
    initializeForm(event) {
      this.form.placeName = this.place.name;
      this.$nextTick(() => {
        // The watcher for placeName will delete trap_no immediately so set it next tick
        this.form.trap_no = event.trap_no;
      });
      this.form.installed_at = event.installed_at;
      this.form.collected_at = event.collected_at;
      this.form.month = event.month;
    },
  },
  watch: {
    event(value) {
      this.initializeForm(value);
    },
    'form.placeName'(newName, oldName) {
      if (newName !== oldName) {
        this.form.trap_no = '';
      }
    },
  },
};
</script>

<style scoped lang="sass">
</style>
