<template>
  <div data-testid="installation_site_events">
    <v-row class="my-3" align="center">
      <v-btn height="56px" text :to="linkTarget">
        <v-row align="center">
          <icon class="mr-2" :type="installationSite.type" />
          <h2
            class="title primary--text"
            data-testid="installation_site_name"
          >
            {{ installationSite.name }}
          </h2>
        </v-row>
      </v-btn>
      <v-spacer />
      <rat-chart-button
        :eventLoader="eventLoader"
        class="hidden-sm-and-down"
        v-if="installationSite.type === SENSOR_TYPES.RAT"
      />
    </v-row>
    <div class="mb-4">
      <round-button
        :icon="expanded ? 'fa-chevron-circle-up' : 'fa-chevron-circle-down'"
        @click="toggleExpanded"
        data-testid="expand"
      >
        {{ $t('expand_button') }}
      </round-button>
    </div>
    <v-row wrap>
      <template v-for="event in displayedEvents">
        <v-flex md3 xs12 class="pa-2 event-wrapper" :key="event.id">
          <event
            class="event"
            :event="event"
            :editable="false"
            @event:selected="showEvent(event)"
          />
        </v-flex>
      </template>
    </v-row>
    <div class="mt-4" v-if="expanded">
      <visible-in-screen-detector
        @visibility-changed="listEndVisibilityChanged"
      />
      <v-progress-linear
        color="primary"
        indeterminate
        rounded
        height="6"
        v-if="eventLoader.loading"
      />
    </div>
    <gallery-view
      v-model="galleryView.index"
      :visible="galleryView.visible"
      @close="galleryView.visible = false"
      :installationSite="installationSite"
      :eventLoader="eventLoader"
    />
  </div>
</template>

<i18n lang="yaml">
ja:
  expand_button: 設置場所を展開

en:
  expand_button: Expand
</i18n>

<script>
import SensorTypes from '@/mixins/sensorTypes';

import Icon from '@/components/Icon';
import RoundButton from '@/components/atoms/RoundButton';
import VisibleInScreenDetector from '@/components/atoms/VisibleInScreenDetector';
import GalleryView from '@/components/GalleryView/GalleryView';

import Event from '../SensorEvents/Event';
import RatChartButton from './RatChartButton';

export default {
  name: 'installation-site-events',
  mixins: [
    SensorTypes,
  ],
  components: {
    Event,
    Icon,
    GalleryView,
    RatChartButton,
    RoundButton,
    VisibleInScreenDetector,
  },
  props: {
    installationSite: Object,
    eventLoader: Object,
    expanded: Boolean,
  },
  data() {
    return {
      galleryView: {
        index: 0,
        visible: false,
      },
    };
  },
  computed: {
    events() {
      return this.eventLoader.events;
    },
    displayedEvents() {
      if (this.expanded) {
        return this.events;
      }

      return this._.take(this.events, 4);
    },
    linkTarget() {
      return { name: 'installation_site', params: { id: this.installationSite.id } };
    },
  },
  methods: {
    loadMoreEventsIfPossible() {
      if (!this.eventLoader.finished) {
        this.eventLoader.loadNext(40);
      }
    },
    listEndVisibilityChanged(visible) {
      if (visible) {
        this.loadMoreEventsIfPossible();
      }
    },
    showEvent(event) {
      this.galleryView.index = this.events.indexOf(event);
      this.galleryView.visible = true;
    },
    toggleExpanded() {
      const newValue = !this.expanded;
      this.$emit('update:expanded', newValue);
    },
  },
};
</script>

<style lang="sass" scoped>
@import 'vuetify/src/styles/styles.sass'

::v-deep .v-btn__content
  display: block

.event-wrapper
  @media #{map-get($display-breakpoints, 'md-and-up')}
    // Keep a 4:3 aspect ratio
    height: calc((100vw / 4) * (3 / 4))

.event
  height: 100%
</style>
