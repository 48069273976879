var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(_vm.componentType, {
    ref: "input",
    tag: "component",
    attrs: {
      dense: "",
      outlined: "",
      "hide-details": "",
      "single-line": "",
      "return-object": false,
      value: _vm.value,
      items: _vm.items,
      label: _vm.label,
      clearable: _vm.clearable,
      "prepend-inner-icon": _vm.icon,
      "data-testid": _vm.dataTestid,
      disabled: _vm.disabled,
      loading: _vm.loading,
    },
    on: {
      input: function (value) {
        return _vm.$emit("input", value)
      },
      keyup: _vm.handleKeyUp,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }