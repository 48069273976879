var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "text-h6 d-flex" },
    [
      _c(
        "div",
        { staticClass: "font-weight-bold mb-0 primary--text" },
        [
          _vm._v(
            "\n    " + _vm._s(_vm.getPlanUserFacingLabel(_vm.sTypeUsage.plan))
          ),
          _c("br"),
          _vm._v(" "),
          _c("i18n", { attrs: { path: "uploads" } }, [
            _c("span", { class: _vm.countColor }, [
              _vm._v(_vm._s(_vm.sTypeUsage.traps_used)),
            ]),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-tooltip",
        {
          attrs: { "min-width": "10", bottom: "" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function (ref) {
                var on = ref.on
                var attrs = ref.attrs
                return [
                  _c(
                    "v-icon",
                    _vm._g(
                      _vm._b(
                        { staticClass: "pl-2", attrs: { color: "grey" } },
                        "v-icon",
                        attrs,
                        false
                      ),
                      on
                    ),
                    [_vm._v("\n        fa-question-circle-o\n      ")]
                  ),
                ]
              },
            },
          ]),
        },
        [
          _vm._v(" "),
          _c("span", {
            domProps: { innerHTML: _vm._s(_vm.$t("explanation")) },
          }),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }