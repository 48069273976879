<template>
  <v-card class="gallery-view gallery-view--insect-v2">
    <gallery-view-toolbar
      :editable="editable"
      :event="event"
      :sensor="sensor"
      :canDownload="canDownload"
      :title="title"
      @download="download"
      @close="$emit('close')"
    >
      <div class="gallery-view__actions" v-if="editable">
        <toolbar-button
          icon="fa-edit"
          :disabled="!canEditDetections"
          @click.native.stop="editing = true; pickedDetectionType = 'all'"
          v-if="!event.updating"
          data-testid="edit"
        >
          {{ $t('menu.edit') }}
        </toolbar-button>
        <toolbar-button icon="fa-refresh" disabled v-else>
          {{ $t('menu.updating') }}
        </toolbar-button>
      </div>
    </gallery-view-toolbar>
    <layout
      :hasPrevious="hasPrevious"
      :hasNext="hasNext"
      @previous="$emit('previous')"
      @next="$emit('next')"
    >
      <template #top>
        <v-row dense>
          <v-col>
            <tag-display :editable="editable" :event="event"/>
          </v-col>
          <v-col cols="auto">
            <gallery-view-fullscreen-enter v-model="fullscreen" />
          </v-col>
        </v-row>
      </template>
      <div>
        <fullscreen v-model="fullscreen">
        <v-carousel v-model="index" touchless :height="null">
          <v-carousel-item v-for="image in images" :key="image.id" eager>
            <picked-insects-draw
              :imageSrc="event.attachments[image]"
              :pickedDetectionType="pickedDetectionType"
              :currentPhotoKey="editTarget"
              :event="event"
              :hideCanvas="hideCanvas"
            >
              <loading-image
                :src="event.attachments[image]"
                :forceLoading="imageLoading"
              />
            </picked-insects-draw>
          </v-carousel-item>
        </v-carousel>
        </fullscreen>
        <slot name="thumbnails" />
      </div>
      <template #side>
        <div class="result-table pa-2">
          <v-checkbox class="pa-0 mb-3" :label="$t('field.original')" v-model="viewOriginal" hide-details color="primary" />
          <insect-count-table
            :insectCounts="event.insect_counts"
            :showRadiobutton="canEditDetections && !imageLoading"
            :pickedDetectionType.sync="pickedDetectionType"
          />
        </div>
      </template>
    </layout>
    <insect-detections-editor
      :event="event"
      :imageSrc="event.attachments[editTarget]"
      :detectionsUrl="detectionsUrl"
      canViewDetectionDetail
      v-model="editing"
      @updated="$emit('pollEvent')"
    >
      <template #header>
        <content-header class="w-100" :title="title" titleClass="title my-1">
          <template #icon><sensor-icon :sensor="sensor" /></template>
          <template #subtitle>
            <div class="overflow-ellipsis title details">
              {{ timestamp.date }}<span class="ml-3" />{{ `${timestamp.time} ${event.count}`}}
            </div>
          </template>
        </content-header>
      </template>
    </insect-detections-editor>
  </v-card>
</template>

<i18n lang="yaml">
ja:
  menu:
    edit: 修正する
    updating: 更新中
  field:
    original: 撮影画像を表示

en:
  menu:
    edit: Edit
    updating: Updating
  field:
    original: Show original photo
</i18n>

<script>
import moment from 'moment';
import { LATEST_VERSION } from '@/mixins/insectTypes';

import InsectDetectionsEditor from '@/components/_legacy/InsectDetectionsEditor';
import PickedInsectsDraw from '@/components/_legacy/PickedInsectsDraw';
import Fullscreen from '@/components/atoms/Fullscreen';
import ToolbarButton from '@/components/atoms/ToolbarButton';
import ContentHeader from '@/components/ContentHeader';
import InsectCountTable from '@/components/InsectCountTable';
import LoadingImage from '@/components/LoadingImage';
import SensorIcon from '@/components/SensorIcon';
import GalleryViewFullscreenEnter from '@/components/GalleryView/FullscreenEnter';
import GalleryViewToolbar from '@/components/GalleryView/Toolbar';
import Layout from '@/components/GalleryView/Layout';
import TagDisplay from '@/components/GalleryView/TagDisplay';

export default {
  name: 'InsectViewV2',
  props: {
    editable: Boolean,
    event: Object,
    sensor: Object,
    hasPrevious: Boolean,
    hasNext: Boolean,
    title: String,
  },
  components: {
    Layout,
    ContentHeader,
    InsectCountTable,
    InsectDetectionsEditor,
    SensorIcon,
    Fullscreen,
    LoadingImage,
    GalleryViewFullscreenEnter,
    GalleryViewToolbar,
    TagDisplay,
    ToolbarButton,
    PickedInsectsDraw,
  },
  computed: {
    canEditDetections() {
      const isImageSrc = !!this.event.attachments[this.currentImage];
      return this.viewMode !== 'original' && isImageSrc && (this.dataVersion === LATEST_VERSION);
    },
    canDownload() {
      return !this.event.updating && !!this.event.attachments[this.currentImage];
    },
    currentImage() {
      return this.images[this.index];
    },
    editTarget() {
      return this.currentImage.replace('detections-', '');
    },
    dataVersion() { // Sets INSECT_TYPES from insectTypes mixin
      return this.event.insect_types_version;
    },
    hideCanvas() {
      return this.viewMode === 'original';
    },
    images() {
      return ['photo_left', 'photo_center', 'photo_right'];
    },
    imageLoading() {
      return this.viewMode !== 'original' && this.event.updating;
    },
    insectCounts() {
      return this.event.insect_counts;
    },
    timestamp() {
      return {
        date: moment.unix(this.event.timestamp).format('YYYY.M.D'),
        time: moment.unix(this.event.timestamp).format('HH:mm'),
      };
    },
    detectionsUrl() {
      return `/api/v2/events/${this.event.id}/insect_detections/${this.editTarget}`;
    },
  },
  data() {
    return {
      index: 0,
      editing: false,
      fullscreen: false,
      viewMode: 'detections',
      pickedDetectionType: 'all',
      viewOriginal: false,
    };
  },
  inject: ['downloadAttachment'],
  methods: {
    download: async function () {
      const resource = (this.viewMode === 'original' ? this.currentImage : `detections-${this.currentImage}`);
      this.downloadAttachment(this.event.id, resource);
    },
  },
  watch: {
    event(newValue, oldValue) {
      if (newValue.id !== oldValue.id) {
        this.viewMode = 'detections';
        this.viewOriginal = false;
        this.pickedDetectionType = 'all';
      }
    },
    viewOriginal(value) {
      if (value) {
        this.viewMode = 'original';
        this.pickedDetectionType = 'all';
      } else {
        this.viewMode = 'detections';
      }
    },
  },
};
</script>

<style scoped lang="sass">
.result-table
  border: 2px solid #ccc

  ::v-deep th.column
    padding: 0 12px

.fullscreen
  .v-carousel
    height: 100%
    padding: 0

    ::v-deep &__controls__item
      color: #fff !important

.v-carousel
  height: calc(65vh + 50px)
  padding-bottom: 50px
  box-sizing: content-box

  ::v-deep &__item,
  ::v-deep .v-responsive__content,
  .loading-image
    height: 100%

  ::v-deep &__controls
    background-color: transparent

    &__item
      color: #999 !important
</style>
