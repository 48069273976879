<template>
  <div>
    <content-header :title="title" titleClass="display-1 font-weight-bold" />
    <div class="d-flex my-4">
      <round-button
        class="mr-2"
        :loading="loading"
        :disabled="loading"
        @click="fetchLatestLogs"
        v-if="isToday"
      >
        {{ $t('buttons.fetch_newer') }}
      </round-button>
      <date-picker-dropdown
        :value="date"
        @input="date => openDayLogs(date)"
        :date-picker-props="{ max: today }"
      >
        <template #activator="{ on, attrs }">
          <round-button v-on="on" v-bind="attrs">
            {{ $t('buttons.show_day_logs') }}
          </round-button>
        </template>
      </date-picker-dropdown>
    </div>
    <v-data-table
      :headers="headers"
      :items="logItems"
      :loading="loading"
      :no-data-text="$t('no_data')"
      disable-sort
      :items-per-page="-1"
      hide-default-footer
      hide-pagination
      show-expand
    >
      <template #item.type="{ item }">
        {{ $t(`types.${item.type}`) }}
      </template>
      <template #item.details="{ item }">
        <code class="short-details">
          {{ item.shortDetails }}
        </code>
      </template>
      <template #expanded-item="{ item }">
        <td colspan=4>
          <a :href="item.url" target="_blank">
            {{ $t('show_in_aws') }}
          </a>
          <div class="details">
            <code>
              <pre>{{ item.details }}</pre>
            </code>
          </div>
        </td>
      </template>
    </v-data-table>
  </div>
</template>

<i18n lang="yaml">
ja:
  title: '{id}の通信記録'
  no_data: データがありません
  show_in_aws: AWSで表示する
  headers:
    timestamp: 時刻
    type: 種類
    details: 詳細
  types:
    request_upload_url: アップロードURLの取得
    upload: アップロード
    health_check: ライフチェック
    update_settings: 設定の登録
    show_settings: 設定の取得
    already_exists: 既に存在する
    other: その他
  buttons:
    fetch_newer: 最新のログを取得
    fetch_older: もっと古いログを取得
    show_day_logs: 日付のログを表示

en:
  title: 'Connection Log of {id}'
  no_data: No data
  show_in_aws: Show in AWS
  headers:
    timestamp: Timestamp
    type: Type
    details: Details
  types:
    request_upload_url: Request Upload URL
    upload: Upload
    health_check: Health Check
    update_settings: Update Settings
    show_settings: Show Settings
    already_exists: Already Exists
    other: Other
  buttons:
    fetch_newer: Get newest logs
    fetch_older: Get older logs
    show_day_logs: Show logs of date
</i18n>

<script>
import Map from 'collections/map';
import moment from 'moment';

import DatePickerDropdown from '@/components/atoms/DatePickerDropdown';
import RoundButton from '@/components/atoms/RoundButton';
import ContentHeader from '@/components/ContentHeader';

const MESSAGE_TYPES = [
  {
    type: 'health_check',
    condition: ({ message, log_group }) => (message.event === 'HTTP_REQUEST') && log_group.includes('health-check'),
    getMessageDetails: message => message.body,
  },
  {
    type: 'update_settings',
    condition: ({ message, log_group }) => (message.event === 'HTTP_REQUEST') && log_group.includes('settings-update'),
    getMessageDetails: message => message.body,
  },
  {
    type: 'show_settings',
    condition: ({ message, log_group }) => (message.event === 'HTTP_REQUEST') && log_group.includes('settings-show'),
    getMessageDetails: () => ({}),
  },
  {
    type: 'request_upload_url',
    condition: ({ message }) => message.event === 'HTTP_REQUEST',
    getMessageDetails: message => message.body,
  },
  {
    type: 'upload',
    condition: ({ message }) => message.type === 'PROCESS_PENDING_UPLOAD',
    getMessageDetails: message => ({ key: message.key, metadata: message.metadata }),
  },
  {
    type: 'already_exists',
    condition: ({ message }) => message.type === 'EVENT_ALREADY_EXISTS',
    getMessageDetails: () => ({}),
  },
  {
    type: 'other',
    condition: () => true,
    getMessageDetails: message => message,
  },
];

export default {
  name: 'activity-logs',
  components: {
    ContentHeader,
    DatePickerDropdown,
    RoundButton,
  },
  mounted: async function () {
    await this.fetchDayLogs();
  },
  computed: {
    date() {
      return this.$route.params.date || this.today;
    },
    entityId() {
      return this.$route.params.id;
    },
    logItems() {
      return this.logItemMap.sorted((prev, next) =>
        moment(next.timestamp).unix() - moment(prev.timestamp).unix(),
      );
    },
    headers() {
      return [
        { text: this.$t('headers.timestamp'), value: 'displayedTimestamp' },
        { text: this.$t('headers.type'), value: 'type' },
        { text: this.$t('headers.details'), value: 'details', cellClass: 'short-details-cell' },
      ];
    },
    title() {
      const title = this.$t('title', { id: this.entityId });
      return `${title} (${this.date})`;
    },
    isToday() {
      return this.date === this.today;
    },
    today() {
      return moment().format('YYYY-MM-DD');
    },
  },
  methods: {
    fetchDayLogs: async function () {
      const params = {
        from: moment(this.date).startOf('day').toISOString(),
        to: moment(this.date).endOf('day').toISOString(),
      };
      await this.fetchLogs(params);
    },
    fetchLatestLogs: async function () {
      const params = {
        limit: 100,
      };
      if (this.logItems.length > 0) {
        params.from = this.logItems[0].timestamp;
      }
      await this.fetchLogs(params);
    },
    fetchLogs: async function (params) {
      this.loading = true;
      const response = await this.$http.get(`/api/activity_logs/${this.entityId}`, { params });
      this.loading = false;
      this.logItemMap = new Map(
        this.logItemMap.map(item => [item.id, item]).concat(
          response.data.map(item => [item.id, this.processItem(item)]),
        ),
      );
    },
    openDayLogs(date) {
      this.logItemMap = new Map();
      this.$router.push(`/admin/activity_logs/${this.entityId}/${date}`);
      this.fetchDayLogs();
    },
    processItem(logItem) {
      const type = MESSAGE_TYPES.find(messageType => messageType.condition(logItem));
      const details = this.formatJSON(type.getMessageDetails(logItem.message));

      return {
        ...logItem,
        displayedTimestamp: this.convertTimestamp(logItem.timestamp),
        type: type.type,
        details,
        shortDetails: this.shortenDetails(details),
      };
    },
    convertTimestamp(timestamp) {
      return moment(timestamp).format('YYYY/MM/DD HH:mm:ss');
    },
    formatJSON(json) {
      return JSON.stringify(json, null, 2);
    },
    shortenDetails(details) {
      // TODO: Summary?
      return details;
    },
  },
  data() {
    return {
      logItemMap: new Map(),
      loading: false,
    };
  },
};
</script>

<style lang="sass" scoped>
@import 'vuetify/src/styles/styles.sass'

::v-deep .v-data-table
  th[role="columnheader"]
    color: white !important
    background-color: map-get($colors, 'grey', 'base')

    i,
    &.active,
    &:hover
      color: white !important

    &[aria-sort="none"] i
      display: none

  tbody
    tr:nth-child(odd):hover td,
    tr:nth-child(even):hover td
      background-color: map-get($colors, 'grey', 'lighten-2')

    tr:nth-child(odd) td
      background-color: map-get($colors, 'grey', 'lighten-3')

  th + th,
  td + td
    border-left: darken(map-get($colors, 'grey', 'lighten-2'), 5%) solid 1px

  td
    padding: 0 24px

  th
    color: white !important

.details
  max-height: 500px
  overflow-y: scroll
  pre
    padding-left: 20px

::v-deep .short-details-cell
  max-width: 50vw
  overflow: hidden
  text-overflow: ellipsis
  white-space: nowrap
</style>
