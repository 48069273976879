<template>
  <div class="organization-form" v-if="show">
    <page-title-area>
      <entity-header>
        {{ newRecord ? $t('label.create_organization') : organization.name }}
      </entity-header>
    </page-title-area>
    <div class="organization-form__content">
      <div class="organization-form__form my-3">
        <v-form v-model="valid" ref="form">
          <form-group>
            <template #label><span>{{ $t('field.name') }}</span></template>
            <template #input>
              <text-field
                class="organization-form__form__name"
                v-model="model.name"
                :edit="editing"
                :rules="[required($t('field.name'))]"
                data-testid="name"
              />
            </template>
          </form-group>
          <form-group>
            <template #label><span>{{ $t('field.address') }}</span></template>
            <template #input>
              <text-field
                class="organization-form__form__address"
                v-model="model.address"
                :edit="editing"
                :rules="[required($t('field.address'))]"
                data-testid="address"
              />
            </template>
          </form-group>
          <form-group>
            <template #label><span>{{ $t('field.phone_number') }}</span></template>
            <template #input>
              <text-field
                class="organization-form__form__phone_number"
                v-model="model.phone_number"
                :edit="editing"
                :rules="[required($t('field.phone_number'))]"
                data-testid="phone_number"
              />
            </template>
          </form-group>
          <form-group v-if="fieldIsEditable('allowed_sensor_types')">
             <template #label><span>{{ $t('field.allowed_sensor_types') }}</span></template>
             <template #input>
               <div>
                <template v-for="t in SENSOR_TYPES">
                  <checkbox
                    :key="`${t}-checkbox`"
                    v-model="model.allowed_sensor_types"
                    :label="SENSOR_TYPE_NAMES[t]"
                    :value="t"
                    :data-testid="`allowed_sensor_types[${t}]`"
                    :edit="editing"
                  />
                  <br :key="`${t}-br`"/>
                </template>
              </div>
            </template>
          </form-group>
          <template v-if="admin">
            <template v-if="stationSensorEnabled">
              <form-group>
                <template #label><span>{{ $t('field.plan') }}</span></template>
                <template #input>
                  <div>
                    <select-field
                      class="organization-form__form__plan"
                      :rules="[required($t('field.plan'))]"
                      :items="planOptions"
                      v-model="model.next_plan"
                      :edit="editing"
                      data-testid="next_plan"
                    />
                  </div>
                </template>
              </form-group>
            </template>
          </template>
          <form-group v-if="fieldIsEditable('active_features')">
             <template #label><span>{{ $t('field.active_features') }}</span></template>
             <template #input>
               <div>
                <template v-for="feature in availableOrganizationFeatures">
                  <checkbox
                    :key="`${feature}-checkbox`"
                    v-model="model.active_features"
                    :label="$t(`checkbox.${feature}`)"
                    :value="feature"
                    :data-testid="`active_features[${feature}]`"
                    :edit="editing"
                  />
                  <br :key="`${feature}-br`"/>
                </template>
              </div>
            </template>
          </form-group>
        </v-form>
        <organization-user-list
          class="mt-3"
          :admin="admin"
          :organization-id="organization.id"
          :organization="organization"
          @appointAsAdmin="appointAsAdmin"
          v-if="!newRecord"
        />
      </div>
      <div class="organization-form__btn-group" v-if="editable">
        <template v-if="editing">
          <round-button
            class="organization-form__btn organization-form__btn___save my-3"
            icon="fa-check-circle"
            fullWidth
            title="save"
            @click="onSave"
          >
            {{ $t('button.save') }}
          </round-button>
          <round-button
            class="organization-form__btn organization-form__btn___cancel my-3"
            icon="fa-arrow-circle-left"
            fullWidth
            @click="onCancel"
          >
            {{ $t('button.cancel') }}
          </round-button>
        </template>
        <template v-else>
          <round-button
            class="organization-form__btn organization-form__btn___edit my-3"
            icon="fa-pencil-square-o"
            fullWidth
            @click="editing = true"
          >
            {{ $t('button.edit') }}
          </round-button>
        </template>
      </div>
    </div>
  </div>
</template>

<i18n lang="yaml">
ja:
  field:
    name: '組織名'
    address: '住所'
    phone_number: 'TEL'
    allowed_sensor_types: '利用可能なセンサー'
    plan: 'プラン'
    active_features: 利用可能な機能

  label:
    create_organization: '組織作成'

  msg:
    update_organization_success: '組織情報を更新しました。'
    update_organization_error: '組織情報の更新に失敗しました。'
    create_organization_success: '組織を作成しました。'
    create_organization_error: '組織の作成に失敗しました。'

  button:
    save: '保存'
    cancel: 'キャンセル'
    edit: '編集'

  checkbox:
    sensor_installation_sites: センサーの設置場所（⚠️開発中プレビュー版）

en:
  field:
    name: 'Name'
    address: 'Address'
    phone_number: 'TEL'
    allowed_sensor_types: 'Allowed Sensor Types'
    plan: 'Plan'
    active_features: Active Features

  label:
    create_organization: 'Create Organization'

  msg:
    update_organization_success: 'Organization information was successfully updated.'
    update_organization_error: 'An error occurred while updating the organization information.'
    create_organization_success: 'Organization was successfully created.'
    create_organization_error: 'An error occurred while creating the organization.'

  button:
    save: 'Save'
    cancel: 'Cancel'
    edit: 'Edit'

  checkbox:
    sensor_installation_sites: Sensor installation sites (⚠️ Preview in Development)
</i18n>

<script>
import { mapActions, mapGetters } from 'vuex';

import Notifications from '@/mixins/notifications';
import PlanOptions from '@/mixins/planOptions';
import SensorTypes from '@/mixins/sensorTypes';

import EntityHeader from '@/components/atoms/EntityHeader';
import PageTitleArea from '@/components/atoms/PageTitleArea';
import RoundButton from '@/components/atoms/RoundButton';
import FormGroup from '@/components/Form/FormGroup';
import TextField from '@/components/Form/TextField';
import SelectField from '@/components/Form/SelectField';
import Validations from '@/components/Form/mixins/form-validations';
import Checkbox from '@/components/Form/Checkbox';
import OrganizationUserList from './OrganizationUserList';

const DEFAULT_MODEL = {
  name: '',
  address: '',
  phone_number: '',
  allowed_sensor_types: [],
  active_features: [],
  current_plan: '', // !!! Never send this to the DB
  next_plan: '',
};

export default {
  name: 'organization-form',
  mixins: [
    Notifications,
    PlanOptions,
    Validations,
    SensorTypes,
  ],
  props: {
    newRecord: Boolean,
    admin: Boolean,
  },
  components: {
    Checkbox,
    EntityHeader,
    FormGroup,
    OrganizationUserList,
    PageTitleArea,
    RoundButton,
    SelectField,
    TextField,
  },
  async beforeMount() {
    if (!this.newRecord) {
      await this.requestOrganization(this.organizationId);
    }
    this.show = true;
  },
  data() {
    return {
      show: this.newRecord,
      editing: this.newRecord,
      valid: false,
      model: {
        ...DEFAULT_MODEL,
      },
    };
  },
  computed: {
    ...mapGetters([
      'getCurrentUser',
      'getOrganization',
    ]),
    availableOrganizationFeatures() {
      return [
        'sensor_installation_sites',
      ];
    },
    stationSensorEnabled() {
      return this.model.allowed_sensor_types.includes('station');
    },
    organization() {
      if (this.newRecord) {
        return {};
      }
      return this.getOrganization(this.organizationId);
    },
    organizationId() {
      if (this.admin) {
        return this.$route.params.id;
      }
      const user = this.getCurrentUser;
      return user.owner_id;
    },
    organizationAdmin() {
      const { admin = {} } = this.organization;
      return admin;
    },
    editable() {
      const { email: adminEmail } = this.organizationAdmin;
      const { email } = this.$store.getters.getCurrentUser;
      return adminEmail === email || this.admin;
    },
  },
  methods: {
    ...mapActions([
      'requestOrganization',
      'createOrganization',
      'updateOrganization',
      'appointOrganizationAdministrator',
    ]),
    handleCreateError: async function (operation) {
      try {
        await operation();
      } catch (_error) {
        this.notifyError('msg.create_organization_error');
      }
    },
    handleUpdateError: async function (operation) {
      try {
        await operation();
      } catch (_error) {
        this.notifyError('msg.update_organization_error');
      }
    },
    onCancel() {
      if (this.newRecord) {
        this.$router.push('/admin/organizations');
      } else {
        this.editing = false;
        this.setModel();
      }
    },
    onSave: async function () {
      if (this.$refs.form.validate()) {
        const params = this.pickParams();
        if (this.newRecord) {
          this.handleCreateError(async () => {
            const { id } = await this.createOrganization(params);
            this.notifySuccess('msg.create_organization_success');
            if (this.admin) {
              this.$router.push(`/admin/organizations/${id}`);
            } else {
              this.$router.push('/organization');
            }
          });
        } else {
          this.handleUpdateError(async () => {
            await this.updateOrganization({ id: this.organization.id, params });
            this.notifySuccess('msg.update_organization_success');
          });
        }
        this.editing = false;
      }
    },
    pickParams() {
      const allowedKeys = this._.keys(DEFAULT_MODEL).filter(key => key !== 'current_plan');
      const result = { ...this.model };
      delete result.current_plan; // never send this to the DB
      if (!this.stationSensorEnabled) {
        delete result.next_plan;
      }
      if (!this.admin) {
        delete result.next_plan;
        delete result.active_features;
        delete result.allowed_sensor_types;
      }
      return this._.pickBy(result, (value, key) => allowedKeys.includes(key));
    },
    setModel() {
      this.model = {
        ...DEFAULT_MODEL,
        ...this.organization,
      };
    },
    appointAsAdmin(user) {
      this.handleUpdateError(async () => {
        await this.appointOrganizationAdministrator({
          id: this.organization.id,
          email: user.email,
        });
        this.notifySuccess('msg.update_organization_success');
        this.editing = false;
      });
    },
    fieldIsEditable(field) {
      if (this.admin) {
        return true;
      } else if (this.editable) {
        const availables = ['name', 'address', 'phone_number', 'organization_admin_id'];
        return availables.includes(field);
      }

      return false;
    },
  },
  watch: {
    organization() {
      this.setModel();
    },
  },
};
</script>

<style scoped lang="sass">
@import 'vuetify/src/styles/styles.sass'

.organization-form
  &__btn-group
    margin: 24px 0

@media #{map-get($display-breakpoints, 'md-and-up')}
  .organization-form
    &__content
      display: flex

    &__form
      width: 50%

    &__btn-group
      margin: 0 40px
      width: 240px

    &__btn ::v-deep .v-btn__content
      padding: 0 32px
</style>
