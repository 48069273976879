import Vue from 'vue';
import VueI18n from 'vue-i18n';

import mixinLocale from './common/locale';

Vue.use(VueI18n);

const DATA_TYPES = {
  IMAGES: 'images',
  VIDEOS: 'videos',
  IMAGE_AND_VIDEOS: 'images_and_videos',
};

export const SENSOR_TYPES = {
  FLY: 'fly',
  FLY_COUNT: 'fly_count',
  FLY_SCAN: 'fly_scan',
  INSECT_V2: 'insect_monitor_v2',
  RAT: 'rat_monitor',
  HYKECAM: 'hykecam',
  STATION: 'station',
  MIRAKUN_DEVICE: 'mirakun_device',
};

export const SENSOR_DEFINITIONS = {
  hykecam: {
    dataType: DATA_TYPES.IMAGE_AND_VIDEOS,
    capabilities: {
      upload: {
        accept: ['image/jpeg', 'image/png', 'video/quicktime'],
      },
      health_check: false,
      threshold: false,
      receives_mails: true,
      replacement: false,
      photoInterval: false,
      viewDetectionDetails: false,
    },
  },
  insect_monitor_v2: {
    dataType: DATA_TYPES.IMAGES,
    capabilities: {
      upload: false,
      health_check: true,
      threshold: true,
      receives_mails: false,
      replacement: false,
      photoInterval: false,
      viewDetectionDetails: true,
    },
  },
  rat_monitor: {
    dataType: DATA_TYPES.VIDEOS,
    capabilities: {
      upload: false,
      health_check: true,
      threshold: false,
      receives_mails: false,
      replacement: false,
      photoInterval: false,
      viewDetectionDetails: false,
    },
  },
  fly_count: {
    dataType: DATA_TYPES.IMAGES,
    capabilities: {
      upload: false,
      health_check: true,
      threshold: true,
      receives_mails: false,
      replacement: true,
      photoInterval: true,
      viewDetectionDetails: false,
    },
  },
  fly_scan: {
    dataType: DATA_TYPES.IMAGES,
    capabilities: {
      upload: false,
      health_check: true,
      threshold: true,
      receives_mails: false,
      replacement: true,
      photoInterval: true,
    },
  },
  fly: {
    dataType: DATA_TYPES.IMAGES,
    capabilities: {
      upload: false,
      health_check: true,
      threshold: true,
      receives_mails: false,
      replacement: true,
      photoInterval: true,
      viewDetectionDetails: true,
    },
  },
};

// eslint-disable-next-line
const i18n = new VueI18n({
  locale: 'en',
  messages: {
    en: {
      fly: 'F-type',
      fly_count: 'FC-type',
      fly_scan: 'FS-type',
      insect_monitor_v2: 'Insect sensors(3 camera)',
      rat_monitor: 'R-type',
      hykecam: 'Hyke Cam',
      station: 'S-type',
      mirakun_device: 'Chemical treatment system',
    },
    ja: {
      fly: 'F型',
      fly_count: 'FC型',
      fly_scan: 'FS型',
      insect_monitor_v2: '捕虫器センサー(3台)',
      rat_monitor: 'R型',
      hykecam: 'ハイクカム',
      station: 'S型',
      mirakun_device: '自動投薬機',
    },
  },
});

export default {
  computed: {
    SENSOR_DEFINITIONS() {
      return SENSOR_DEFINITIONS;
    },
    SENSOR_TYPES() {
      return SENSOR_TYPES;
    },
    SENSOR_TYPE_NAMES() {
      const entries = Object.values(SENSOR_TYPES).map((value) =>
        ([value, i18n.t(value, this.i18n_locale)]),
      );
      return Object.fromEntries(entries);
    },
    SENSOR_TYPE_INDEXES() {
      const entries = Object.values(SENSOR_TYPES).map((value, index) => ([value, index]));
      return Object.fromEntries(entries);
    },
  },
  ...mixinLocale,
};
