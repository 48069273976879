<template>
  <v-slide-group
    show-arrows="always"
    center-active
    :value="value"
    ref="slideGroup"
  >
    <v-slide-item
      v-for="(item, index) in items"
      :key="item[keyName]"
    >
      <slot
        name="item"
        :item="item"
        :index="index"
      />
    </v-slide-item>
  </v-slide-group>
</template>

<script>
export default {
  name: 'thumbnail-slider',
  props: {
    value: Number,
    keyName: String,
    items: {
      type: Array, // any
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      slideGroup: null,
    };
  },
  computed: {
    hasNext() {
      return this.slideGroup && this.slideGroup.hasNext;
    },
  },
  methods: {
    callScrollEndIfNecessary() {
      if (!this.hasNext) {
        this.$emit('scrolledToEnd');
      }
    },
  },
  mounted() {
    this.slideGroup = this.$refs.slideGroup;
    this.callScrollEndIfNecessary();
  },
  watch: {
    hasNext() {
      this.callScrollEndIfNecessary();
    },
  },
};
</script>

<style scoped lang="sass">
</style>
