import Vue from 'vue';
import VueI18n from 'vue-i18n';
import _ from 'lodash';
import moment from 'moment';

import { rowArrayToCSVString } from '@/libs/csv';
import SensorTypes from '@/mixins/sensorTypes';
import InsectTypes from '@/mixins/insectTypes';

import mixinLocale from './common/locale';

Vue.use(VueI18n);

const messages = {
  en: {
    csv: {
      headers: {
        number: 'No',
        organization_name: 'Organization name',
        user_name: 'User name',
        sensor_name: 'Sensor name',
        sensor_id: 'Sensor id',
        place: 'Place',
        installed_on: 'Installed on',
        threshold: 'Threshold when photo was uploaded',
        increase_threshold: 'Increase Threshold when photo was uploaded',
        memo: 'Memo',
        insect_timestamp: 'Captured at',
        rat_timestamp: 'Start capture at',
        total: 'Total',
        detected: 'Detected',
        detected_from: 'Detected from',
        detected_to: 'Detected to',
        pickup: 'Pickup',
        misdetection: 'Misdetection',
        tags: 'Tags',
      },
    },
  },
  ja: {
    csv: {
      headers: {
        number: 'No',
        organization_name: '組織名',
        user_name: 'ユーザー名',
        sensor_name: 'センサー名',
        sensor_id: '識別子',
        place: '設置場所',
        installed_on: '設置日',
        threshold: 'アップロード時のしきい値',
        increase_threshold: 'アップロード時の増加量のしきい値',
        memo: 'メモ',
        insect_timestamp: '撮影日時',
        rat_timestamp: '動画開始日時',
        total: '合計',
        detected: '出現',
        detected_from: '出現開始日時',
        detected_to: '出現終了日時',
        pickup: 'ピックアップ',
        misdetection: '誤検出',
        tags: 'タグ',
      },
    },
  },
};

const i18n = new VueI18n({ locale: 'en', messages });
const MOMENT_FORMAT = 'YYYY/MM/DD HH:mm:ss';

export default {
  mixins: [
    InsectTypes,
    SensorTypes,
  ],
  methods: {
    generateCsv(sensor, events) {
      const columns = this.genCsvColumns(sensor);
      const headers = this.genCsvHeaders(columns, sensor);
      const rows = this.genCsvRows(columns, sensor, events);
      return rowArrayToCSVString([
        headers,
        ...rows,
      ]);
    },
    genCsvColumns(sensor) {
      switch (sensor.type) {
        case this.SENSOR_TYPES.RAT:
          return this.RAT_CSV_COLUMNS;
        case this.SENSOR_TYPES.INSECT_V2:
          return this.buildInsectCSVColumns(sensor, this.INSECT_TYPES);
        case this.SENSOR_TYPES.FLY:
        case this.SENSOR_TYPES.FLY_SCAN:
        case this.SENSOR_TYPES.FLY_COUNT: {
          const columns = this.buildInsectCSVColumns(sensor, this.INSECT_TYPES);

          const thresholdIndex = this._.findIndex(columns, { header: 'threshold' });
          columns.splice(thresholdIndex, 1, {
            header: 'threshold',
            cell: ({ event }) => event.sensor_threshold,
          });
          columns.splice(thresholdIndex + 1, 0, {
            header: 'increase_threshold',
            cell: ({ event }) => {
              if (event.sensor_increase_threshold === null) {
                return '-';
              }

              return event.sensor_increase_threshold;
            },
          });
          return columns;
        }
        default:
          return [];
      }
    },
    genCsvHeaders(columns, sensor) {
      const headers = this._.map(columns, 'header');
      return headers.map((header, index) => {
        if (typeof header === 'string') {
          return i18n.t(`csv.headers.${header}`, this.i18n_locale);
        }
        return header({ sensor }, index);
      });
    },
    genCsvRows(columns, sensor, events) {
      const cells = this._.map(columns, 'cell');
      return events.map((event, index) => cells.map((cell) => {
        const data = { event, sensor };
        if (typeof cell === 'string') {
          return this._.get(data, cell);
        }
        return cell(data, index);
      }));
    },
    buildInsectCSVColumns(sensor, insectTypes) {
      const columns = [...this.INSECT_CSV_COLUMNS];
      const canViewDetectionDetails = this.canViewDetectionDetails(sensor);
      if (canViewDetectionDetails) {
        const index = _.findIndex(columns, { header: 'pickup' });
        const detections = _.map(insectTypes, (type, key) => ({
          header: () => `${type.symbol}. ${type.label}`,
          cell: ({ event }) => event.insect_counts[key] || 0,
        }));
        columns.splice(index, 0, ...detections);
      }
      return columns;
    },
    canViewDetectionDetails(sensor) {
      const definition = this.SENSOR_DEFINITIONS[sensor.type];
      return definition.capabilities.viewDetectionDetails;
    },
  },
  computed: {
    COMMON_CSV_COLUMNS() {
      return [
        {
          header: 'number',
          cell: (_row, index) => index + 1,
        },
        {
          header: 'organization_name',
          cell: 'sensor.created_by.organization_name',
        },
        {
          header: 'user_name',
          cell: 'sensor.created_by.name',
        },
        {
          header: 'sensor_name',
          cell: 'sensor.name',
        },
        {
          header: 'sensor_id',
          cell: 'sensor.id',
        },
        {
          header: 'place',
          cell: 'sensor.place',
        },
        {
          header: 'installed_on',
          cell: ({ sensor }) => moment(sensor.installed_on).format('YYYY/MM/DD'),
        },
      ];
    },
    INSECT_CSV_COLUMNS() {
      return [
        ...this.COMMON_CSV_COLUMNS,
        {
          header: 'threshold',
          cell: 'sensor.threshold',
        },
        {
          header: 'memo',
          cell: 'sensor.memo',
        },
        {
          header: 'insect_timestamp',
          cell: ({ event }) => moment.unix(event.timestamp).format('YYYY/MM/DD HH:mm:ss'),
        },
        {
          header: 'total',
          cell: 'event.count',
        },
        // insert detection details
        {
          header: 'pickup',
          cell: ({ event }) => (event.pickup ? 1 : 0),
        },
        {
          header: 'tags',
          cell: ({ event }) => (event.tags || []).join(','),
        },
      ];
    },
    RAT_CSV_COLUMNS() {
      return [
        {
          header: 'memo',
          cell: 'sensor.memo',
        },
        {
          header: 'rat_timestamp',
          cell: ({ event }) =>
            moment.unix(event.captured_at || event.timestamp).format(MOMENT_FORMAT),
        },
        {
          header: 'detected',
          cell: ({ event }) => (event.detected ? 1 : 0),
        },
        {
          header: 'detected_from',
          cell: ({ event }) => (event.from ? moment.unix(event.from).format(MOMENT_FORMAT) : ''),
        },
        {
          header: 'detected_to',
          cell: ({ event }) => (event.to ? moment.unix(event.to).format(MOMENT_FORMAT) : ''),
        },
        {
          header: 'pickup',
          cell: ({ event }) => (event.pickup ? 1 : 0),
        },
        {
          header: 'misdetection',
          cell: ({ event }) => (event.misdetection ? 1 : 0),
        },
        {
          header: 'tags',
          cell: ({ event }) => (event.tags || []).join(','),
        },
      ];
    },
  },
  ...mixinLocale,
};
