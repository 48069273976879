<template>
  <div class="sign-up">
    <img src="../assets/logo.png" class="mb-4" width="140">
    <template v-if="success">
      <p v-html="$t('label.signup_complete')"></p>
      <v-btn color="primary" large block to="/sign_in">
        {{ $t('button.close') }}
        <v-icon dark right>fa-times-circle</v-icon>
      </v-btn>
    </template>
    <template v-else>
      <h4 class="text-h4 mb-4" v-if="submitted">{{ $t('label.sign_up_information') }}</h4>
      <h4 class="text-h4 mb-4" v-else>{{ $t('label.sign_up') }}</h4>
      <v-alert type="error" v-if='error !== ""'>{{error}}</v-alert>
      <v-form :class="{ submitted }" v-model="valid" ref="form">
        <form-group hide-border required>
          <template #label><span>{{$t('field.email')}}</span></template>
          <template #input>
            <text-field
              class="sign-up__email"
              type="email"
              :value="email"
              disabled />
          </template>
        </form-group>
        <form-group hide-border required>
          <template #label><span>{{$t('field.name')}}</span></template>
          <template #input>
            <text-field
              class="sign-up__name"
              v-model="model.name"
              :rules="rules.name"
              :edit="!submitted" />
          </template>
        </form-group>
        <form-group hide-border required>
          <template #label><span>{{$t('field.password')}}</span></template>
          <template #input>
            <text-field
              class="sign-up__password"
              type="password"
              v-model="model.password"
              :rules="rules.password"
              :edit="!submitted" />
          </template>
        </form-group>
        <form-group hide-border required>
          <template #label><span>{{$t('field.password_confirm')}}</span></template>
          <template #input>
            <text-field
              class="sign-up__password_confirm"
              type="password"
              v-model="model.password_confirm"
              :rules="rules.password_confirm"
              :edit="!submitted" />
          </template>
        </form-group>
        <form-group class="sign-up__locale" hide-border required>
          <template #label><span>{{$t('field.locale')}}</span></template>
          <template #input>
            <select-field
              v-model="model.locale"
              :items="locale_options"
              :rules="[required($t('field.locale'))]"
              :edit="!submitted" />
          </template>
        </form-group>
        <template v-if="submitted">
          <v-btn class="mb-3" color="primary" large block @click="submitted = false">
            {{ $t('button.back') }}
            <v-icon dark right>fa-undo</v-icon>
          </v-btn>
          <v-btn class="sign-up__register" color="primary" large block @click="onRegister">
            {{ $t('button.register') }}
            <v-icon dark right>fa-check</v-icon>
          </v-btn>
        </template>
        <template v-else>
          <v-btn class="sign-up__submit mb-5" color="primary" large block @click="onSubmit">
            {{ $t('button.send') }}
            <v-icon dark right>fa-arrow-circle-right</v-icon>
          </v-btn>
        </template>
      </v-form>
    </template>
    <terms-modal v-model="modal.terms" :onAccept="allowRegister" :onCancel="moveToSignin" />
  </div>
</template>

<i18n lang="yaml">
ja:
  label:
    sign_up: '利用申込'
    sign_up_information: '登録情報'
    signup_complete: '利用申込が完了しました。<br/>入力メールアドレス宛に確認用のメールを<br/>送信しましたのでご確認ください。'

  field:
    email: 'E-mail'
    name: '氏名'
    locale: '言語'
    password: 'パスワード'
    password_confirm: 'パスワード（確認）'

  button:
    send: '送信'
    register: '登録'
    back: '戻る'
    close: '閉じる'

en:
  label:
    sign_up: 'Sign Up'
    sign_up_information: 'Entered Information'
    signup_complete: 'Your registration is complete.<br/>A confirmation mail was sent to you.'

  field:
    email: 'E-mail'
    name: 'Name'
    locale: 'Language'
    password: 'Password'
    password_confirm: 'Password (confirm)'

  button:
    send: 'Send'
    register: 'Register'
    back: 'Back'
    close: 'Close'
</i18n>

<script>
import Locales from '@/mixins/locales';
import Validations from '@/components/Form/mixins/form-validations';
import FormGroup from '@/components/Form/FormGroup';
import SelectField from '@/components/Form/SelectField';
import TextField from '@/components/Form/TextField';
import TermsModal from '@/components/TermsModal';

export default {
  name: 'sign-up',
  components: { TermsModal, FormGroup, SelectField, TextField },
  mixins: [Locales, Validations],
  data() {
    return {
      modal: {
        terms: false,
      },
      model: {
        name: '',
        locale: '',
        password: '',
        password_confirm: '',
      },
      valid: true,
      submitted: false,
      success: false,
      error: '',
    };
  },
  methods: {
    allowRegister() {
      this.modal.terms = false;
    },
    moveToSignin() {
      this.$router.push('/sign_in');
    },
    onRegister: async function () {
      const params = {
        ...this.model,
        email: this.email,
      };
      delete params.password_confirm;
      try {
        await this.$store.dispatch('registerUser', params);
        this.success = true;
      } catch (error) {
        this.error = error.response.data.message;
      }
    },
    onSubmit() {
      this.$refs.form.validate(true);
      if (this.valid) {
        this.submitted = true;
      }
    },
  },
  mounted() {
    this.model.locale = this.i18n_locale;
    this.modal.terms = true; // delay to display modal
  },
  computed: {
    email() {
      return this.$route.query.email;
    },
    rules() {
      return {
        name: [this.required(this.$t('field.name'))],
        password: [
          this.required(this.$t('field.password')),
          this.password_length(),
          this.password_characters(),
        ],
        password_confirm: [
          this.required(this.$t('field.password_confirm')),
          this.match_password(),
        ],
      };
    },
  },
  watch: {
    i18n_locale() {
      this.$refs.form.resetValidation();
    },
  },
};
</script>

<style scoped lang="sass">
@import 'vuetify/src/styles/styles.sass'

.sign-up
  margin: 0 -50px

  ::v-deep .alert
    border: 0

  ::v-deep .form-group
    color: black
    font-size: 16px

    &__item
      text-align: left

    &__item--label
      padding-left: 0
      padding-right: 0

    &__item--input
      padding-right: 0

  ::v-deep .v-input.select-box .v-input__slot
    margin: 0
</style>
