// Biggest event JSON is around 2KB
// Max Response size is 6MB
// To be safe, we load 2000 events (~4MB) at a time
const LOAD_ALL_BATCH_SIZE = 2000;

const calculateProgress = (events, oldestEvent) => {
  const newestEvent = events[0];
  const totalTimespan = newestEvent.timestamp - oldestEvent.timestamp;
  if (totalTimespan === 0) {
    return 1;
  }

  const oldestLoadedEvent = events[events.length - 1];
  const loadedTimespan = newestEvent.timestamp - oldestLoadedEvent.timestamp;

  return loadedTimespan / totalTimespan;
};

export const buildEventLoader = ({ fetchEvents, params }) => {
  const loader = {
    events: [],
    loading: false,
    finished: false,
    progress: 0,
  };

  const withLoadingState = (fn) => async (...args) => {
    loader.loading = true;

    try {
      return await fn(...args);
    } finally {
      loader.loading = false;
    }
  };

  // Keep as separate function to be used inside loadAll without updating the loading state
  const loadNext = async (count) => {
    const lastEvent = loader.events[loader.events.length - 1];

    const fetchParams = { ...params, limit: count };
    if (lastEvent) {
      fetchParams.older_than_event = lastEvent.id;
    }

    const response = await fetchEvents(fetchParams);

    loader.events = loader.events.concat(response);
    loader.finished = response.length < count;
    return response;
  };

  loader.loadAll = withLoadingState(async () => {
    const [oldestEvent] = await fetchEvents({ ...params, limit: 1, ascending: true });

    while (!loader.finished) {
      // eslint-disable-next-line no-await-in-loop
      await loadNext(LOAD_ALL_BATCH_SIZE);

      loader.progress = calculateProgress(loader.events, oldestEvent);
    }

    return loader.events;
  });

  loader.loadNext = withLoadingState(loadNext);

  loader.copy = () => {
    const copy = buildEventLoader({ fetchEvents, params });
    copy.events = [...loader.events];
    copy.finished = loader.finished;
    copy.progress = loader.progress;
    return copy;
  };

  return loader;
};
