var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "gallery-view" },
    [
      _c(
        "gallery-view-toolbar",
        {
          attrs: {
            editable: _vm.editable,
            event: _vm.event,
            sensor: _vm.sensor,
            canDownload: !_vm.event.updating,
            downloadOptions: _vm.downloadOptions,
            title: _vm.title,
          },
          on: {
            download: _vm.download,
            close: function ($event) {
              return _vm.$emit("close")
            },
          },
        },
        [
          _vm.editable
            ? [
                !_vm.event.updating
                  ? [
                      _c(
                        "toolbar-button",
                        {
                          staticClass: "hidden-sm-and-down",
                          attrs: { icon: "fa-edit", "data-testid": "edit" },
                          nativeOn: {
                            click: function ($event) {
                              $event.stopPropagation()
                              _vm.editing = true
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("menu.edit")) +
                              "\n        "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "toolbar-button",
                        {
                          attrs: { icon: "fa-trash" },
                          nativeOn: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.$emit("deleteEvent")
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("menu.delete")) +
                              "\n        "
                          ),
                        ]
                      ),
                    ]
                  : _c(
                      "toolbar-button",
                      { attrs: { icon: "fa-refresh", disabled: "" } },
                      [
                        _vm._v(
                          "\n        " +
                            _vm._s(_vm.$t("menu.updating")) +
                            "\n      "
                        ),
                      ]
                    ),
              ]
            : _vm._e(),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "layout",
        {
          staticClass: "mb-3",
          attrs: { hasPrevious: _vm.hasPrevious, hasNext: _vm.hasNext },
          on: {
            previous: function ($event) {
              return _vm.$emit("previous")
            },
            next: function ($event) {
              return _vm.$emit("next")
            },
          },
          scopedSlots: _vm._u([
            {
              key: "top",
              fn: function () {
                return [
                  _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [
                      _c(
                        "v-col",
                        [
                          _c("tag-display", {
                            attrs: { editable: _vm.editable, event: _vm.event },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        { attrs: { cols: "auto" } },
                        [
                          _c("gallery-view-fullscreen-enter", {
                            model: {
                              value: _vm.fullscreen,
                              callback: function ($$v) {
                                _vm.fullscreen = $$v
                              },
                              expression: "fullscreen",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
            {
              key: "side",
              fn: function () {
                return [
                  _c(
                    "div",
                    { staticClass: "result pa-2" },
                    [
                      _c(
                        "v-row",
                        {
                          attrs: {
                            "no-gutters": "",
                            align: "center",
                            justify: "space-between",
                          },
                        },
                        [
                          _c("v-checkbox", {
                            staticClass: "mt-0 pt-0",
                            attrs: {
                              label: _vm.$t("field.original"),
                              "hide-details": "",
                              color: "primary",
                            },
                            model: {
                              value: _vm.viewOriginal,
                              callback: function ($$v) {
                                _vm.viewOriginal = $$v
                              },
                              expression: "viewOriginal",
                            },
                          }),
                          _vm._v(" "),
                          _c("chart-type-select", {
                            model: {
                              value: _vm.chartType,
                              callback: function ($$v) {
                                _vm.chartType = $$v
                              },
                              expression: "chartType",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.chartType == "table"
                        ? _c("insect-count-table", {
                            attrs: {
                              insectCounts: _vm.event.insect_counts,
                              showRadiobutton: !_vm.event.updating,
                              pickedDetectionType: _vm.pickedDetectionType,
                              showFocusButton: "",
                              tableMaxHeight: "65vh",
                            },
                            on: {
                              "update:pickedDetectionType": function ($event) {
                                _vm.pickedDetectionType = $event
                              },
                              "update:picked-detection-type": function (
                                $event
                              ) {
                                _vm.pickedDetectionType = $event
                              },
                              "click:focus": _vm.handleFocus,
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.chartType == "bar"
                        ? _c("insect-count-by-type-bar-chart", {
                            attrs: { insectCounts: _vm.event.insect_counts },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.chartType == "pie"
                        ? _c("top-5-insect-types-pie-chart", {
                            attrs: { insectCounts: _vm.event.insect_counts },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _vm._v(" "),
          _c("div", { staticClass: "gallery-view__image" }, [
            _c(
              "div",
              { staticClass: "gallery-view__image__container" },
              [
                _c(
                  "fullscreen",
                  {
                    staticClass: "gallery-view__image__fullscreen",
                    model: {
                      value: _vm.fullscreen,
                      callback: function ($$v) {
                        _vm.fullscreen = $$v
                      },
                      expression: "fullscreen",
                    },
                  },
                  [
                    _c("picked-insects-draw", {
                      ref: "drawer",
                      attrs: {
                        imageSrc: _vm.event.attachments["display-photo"],
                        detectionsUrl: _vm.detectionsUrl,
                        hideCanvas: _vm.viewOriginal,
                        pickedDetectionType: _vm.pickedDetectionType,
                        originalImageWidth: _vm.ORIGINAL_IMAGE_WIDTH,
                        originalImageHeight: _vm.ORIGINAL_IMAGE_HEIGHT,
                      },
                      on: { save: _vm.save },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "gallery-view__difference" },
            [
              _c(
                "div",
                [
                  _c("div", [
                    _c("span", [_vm._v(_vm._s(_vm.$t("field.total")))]),
                    _vm._v(" "),
                    _c("span", { staticClass: "mx-1" }, [_vm._v(":")]),
                    _vm._v(" "),
                    _c("span", [_vm._v(_vm._s(_vm.event.count))]),
                  ]),
                  _vm._v(" "),
                  _c("insect-count-difference", {
                    attrs: { event: _vm.event },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("hr"),
              _vm._v(" "),
              _c("threshold-alert-status", {
                staticClass: "flex-grow-1",
                attrs: {
                  event: _vm.event,
                  sensor: _vm.sensor,
                  editable: _vm.editable,
                  showAlert: _vm.event.latest,
                  updatableThreshold: _vm.event.latest,
                },
              }),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _vm._t("thumbnails"),
      _vm._v(" "),
      _c("insect-detections-editor", {
        attrs: {
          event: _vm.event,
          imageSrc: _vm.event.attachments["display-photo"],
          detectionsUrl: _vm.detectionsUrl,
          originalImageWidth: _vm.ORIGINAL_IMAGE_WIDTH,
          originalImageHeight: _vm.ORIGINAL_IMAGE_HEIGHT,
          canViewDetectionDetail: "",
        },
        on: {
          updated: function ($event) {
            return _vm.$emit("pollEvent")
          },
        },
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function () {
              return [
                _c("content-header", {
                  staticClass: "w-100",
                  attrs: { title: _vm.title, titleClass: "title my-1" },
                  scopedSlots: _vm._u([
                    {
                      key: "icon",
                      fn: function () {
                        return [
                          _c("sensor-icon", { attrs: { sensor: _vm.sensor } }),
                        ]
                      },
                      proxy: true,
                    },
                    {
                      key: "subtitle",
                      fn: function () {
                        return [
                          _c(
                            "div",
                            { staticClass: "overflow-ellipsis title details" },
                            [
                              _vm._v(
                                "\n            " + _vm._s(_vm.timestamp.date)
                              ),
                              _c("span", { staticClass: "ml-3" }),
                              _vm._v(
                                _vm._s(
                                  _vm.timestamp.time + " " + _vm.event.count
                                ) + "\n          "
                              ),
                            ]
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                }),
              ]
            },
            proxy: true,
          },
        ]),
        model: {
          value: _vm.editing,
          callback: function ($$v) {
            _vm.editing = $$v
          },
          expression: "editing",
        },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }