<template>
  <component
    :is="componentType"
    dense
    outlined
    hide-details
    single-line
    :return-object="false"
    :value="value"
    :items="items"
    :label="label"
    :clearable="clearable"
    :prepend-inner-icon="icon"
    :data-testid="dataTestid"
    :disabled="disabled"
    :loading="loading"
    @input="value => $emit('input', value)"
    @keyup="handleKeyUp"
    ref="input"
  />
</template>

<script>
export default {
  name: 'autocomplete-text-field',
  props: {
    value: {
      type: String,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: null,
    },
    allowNewValues: {
      type: Boolean,
      default: false,
    },
    ariaLabel: {
      type: String,
      default: null,
    },
    disabled: Boolean,
    dataTestid: String,
    loading: Boolean,
  },
  computed: {
    componentType() {
      return this.allowNewValues ? 'v-combobox' : 'v-autocomplete';
    },
  },
  methods: {
    handleKeyUp(event) {
      if (this.allowNewValues) {
        this.$emit('input', event.target.value);
      }
    },
  },
  mounted() {
    this.$refs.input.$refs.input.setAttribute('aria-label', this.ariaLabel);
  },
};
</script>
