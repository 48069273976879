var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.featureIsActive("sensor_installation_sites")
    ? _c("activity-legacy")
    : _c(
        "div",
        [
          _c(
            "page-title-area",
            {
              scopedSlots: _vm._u([
                {
                  key: "side-content",
                  fn: function () {
                    return [
                      _c(
                        "v-btn",
                        {
                          attrs: { text: "" },
                          on: { click: _vm.expandAllSites },
                        },
                        [
                          _c(
                            "v-icon",
                            { attrs: { left: "", color: "primary" } },
                            [_vm._v("fa-chevron-circle-down")]
                          ),
                          _vm._v(
                            "\n        " +
                              _vm._s(_vm.$t("expand_all_button")) +
                              "\n      "
                          ),
                        ],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                },
              ]),
            },
            [_c("h1", [_vm._v("ACTIVITY")])]
          ),
          _vm._v(" "),
          _c("query-filter", {
            staticClass: "mb-4",
            attrs: { loading: _vm.loading, clearable: _vm.displaysAnyEvents },
            on: { apply: _vm.executeQuery, clear: _vm.clearQuery },
          }),
          _vm._v(" "),
          _vm._l(_vm.eventLoadersWithData, function (ref, index) {
            var installationSite = ref.installationSite
            var eventLoader = ref.eventLoader
            return [
              _c(
                "div",
                { key: installationSite.id },
                [
                  _c("installation-site-events", {
                    attrs: {
                      "installation-site": installationSite,
                      eventLoader: eventLoader,
                      expanded: _vm.expandedSiteIds.includes(
                        installationSite.id
                      ),
                    },
                    on: {
                      "update:expanded": function (value) {
                        return _vm.updateSiteExpandedState(
                          installationSite.id,
                          value
                        )
                      },
                    },
                  }),
                  _vm._v(" "),
                  index < _vm.eventLoadersWithData.length - 1
                    ? _c("v-divider", { staticClass: "mb-4 mt-8" })
                    : _vm._e(),
                ],
                1
              ),
            ]
          }),
        ],
        2
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }