<template>
  <transition name="toggle-nav" v-if="visible" class="navigation">
    <div class="navigation__panel">
      <v-list>
        <v-list-item class="navigation__item" v-for="item in items" :key="item.title" :to="item.path" @click="closeNav()">
          <v-list-item-content :data-testid="`navigation__item--${item.path}`">
            {{ item.title }}
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-subheader class="primary--text" v-text="userName"></v-subheader>
        <v-list-item class="navigation__item" :to="accountPath" @click="closeNav()">
          <v-list-item-title>{{ accountLabel }}</v-list-item-title>
        </v-list-item>
        <v-list-item v-if="isPartOfOrganization" class="navigation__item" :to="organizationPath" @click="closeNav()">
          <v-list-item-title>{{ organizationLabel }}</v-list-item-title>
        </v-list-item>
        <v-list-item
          class="navigation__item navigation__logout"
          data-testid="navigation__logout"
          @click="signOut()">
          <v-list-item-title>{{ logoutLabel }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </div>
  </transition>
</template>

<script>
import Navigation from '@/mixins/navigation';

export default {
  name: 'navigation',
  mixins: [
    Navigation,
  ],
  props: {
    visible: Boolean,
  },
  model: {
    prop: 'visible',
    event: 'change',
  },
  methods: {
    closeNav() {
      this.$emit('change', false);
    },
  },
};
</script>

<style scoped lang="sass">
@import 'vuetify/src/styles/styles.sass'
.navigation
  &__panel
    width: 100%
    padding: 10px 60px
    background: white
    position: absolute
    top: 56px
    z-index: 2
    overflow: hidden
    max-height: 100%

    ::v-deep .v-divider
      background-color: #ccc!important

    ::v-deep .v-list__tile__content,
    ::v-deep .v-list__tile__title
      text-transform: uppercase

.toggle-nav-enter-active, .toggle-nav-leave-active
  transition: all .5s

.toggle-nav-enter, .toggle-nav-leave-to
  max-height: 0
  padding: 0 60px

@media #{map-get($display-breakpoints, 'md-and-up')}
  .navigation
    &__panel
      display: none!important
</style>
