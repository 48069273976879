<template>
  <div class="user-form" v-if="show" data-testid="UserForm">
    <page-title-area>
      <entity-header icon="user">
        {{ newRecord ? $t('label.create_account') : user.name }}
      </entity-header>
    </page-title-area>
    <div class="user-form__content">
      <div class="user-form__form my-3">
        <v-form v-model="valid" ref="form">
          <form-group>
            <template #label><span>{{ $t('field.organization') }}</span></template>
            <template #input>
              <select-field
                :items="organization_options"
                v-model="model.owner_id"
                :edit="state.editing"
                data-testid="owner_id"
                v-if="admin"
              />
              <span v-else>
                {{ organization.name }}
              </span>
            </template>
          </form-group>
          <form-group>
            <template #label><span>{{ $t('field.name') }}</span></template>
            <template #input>
              <text-field
                v-model="model.name"
                :edit="state.editing"
                :rules="[required($t('field.name'))]"
                data-testid="name"
              />
            </template>
          </form-group>
          <form-group>
            <template #label><span>{{ $t('field.email') }}</span></template>
            <template #input>
              <text-field
                type="email"
                v-model="model.email"
                :edit="state.editing"
                :rules="[required($t('field.email')), email($t('field.email'))]"
                data-testid="email"
              />
            </template>
          </form-group>
          <form-group>
            <template #label><span>{{ $t('field.locale') }}</span></template>
            <template #input>
              <select-field
                :items="locale_options"
                v-model="model.locale"
                :edit="state.editing"
                :rules="[required($t('field.locale'))]"
                data-testid="locale"
              />
            </template>
          </form-group>
          <template v-if="admin">
            <form-group>
               <template #label><span>{{ $t('field.allowed_sensor_types') }}</span></template>
               <template #input>
                 <div>
                  <template v-for="t in sensorTypesForUser">
                    <checkbox
                      :key="`${t}-checkbox`"
                      v-model="model.allowed_sensor_types"
                      :label="SENSOR_TYPE_NAMES[t]"
                      :value="t"
                      :edit="state.editing"
                      :data-testid="`allowed_sensor_type_${t}`"
                    />
                    <br :key="`${t}-br`"/>
                  </template>
                </div>
              </template>
            </form-group>
            <template v-if="stationEnabled">
              <form-group>
                <template #label><span>{{ $t('field.plan') }}</span></template>
                <template #input>
                  <div>
                    <select-field
                      :rules="[required($t('field.plan'))]"
                      :items="planOptions"
                      v-model="model.next_plan"
                      :edit="state.editing"
                      data-testid="plan"
                    />
                  </div>
                  <span
                    v-if="planChangedAlertMessage"
                    data-testid="plan-alert"
                  >
                    {{ `${planChangedAlertMessage}` }}
                  </span>
                </template>
              </form-group>
            </template>
          </template>
          <form-group v-if="mirakunDeviceEnabled">
            <template #label><span>{{ $t('field.mirakun_user_id') }}</span></template>
            <template #input>
              <text-field
                v-model="model.mirakun_user_id"
                :edit="state.editing"
                data-testid="mirakun_user_id"
              />
            </template>
          </form-group>
          <form-group v-if="state.editing">
            <template #label><span>{{ $t('field.password') }}</span></template>
            <template #input>
              <text-field
                type="password"
                v-model="model.password"
                :edit="state.editing"
                :rules="[rules.password_required, password_length(), password_characters()]"
                data-testid="password"
              />
            </template>
          </form-group>
          <form-group v-if="state.editing">
            <template #label><span>{{ $t('field.password_confirm') }}</span></template>
            <template #input>
              <text-field
                type="password"
                v-model="model.password_confirm"
                :edit="state.editing"
                :rules="[match_password()]"
                data-testid="password_confirm"
              />
            </template>
          </form-group>
          <form-group v-if="admin">
            <template #label><span>{{ $t('field.invited_by') }}</span></template>
            <template #input>
              {{ user.invited_by | invitedBy }}
            </template>
          </form-group>
        </v-form>
      </div>
      <div class="user-form__btn-group">
        <template v-if="state.editing">
          <round-button
            class="user-form__btn my-3"
            icon="fa-check-circle"
            fullWidth
            title="save"
            @click="onSave"
            data-testid="save"
          >
            {{ $t('button.save') }}
          </round-button>
          <round-button
            class="user-form__btn my-3"
            icon="fa-arrow-circle-left"
            fullWidth
            @click="onCancel"
            data-testid="cancel"
          >
            {{ $t('button.cancel') }}
          </round-button>
        </template>
        <template v-else>
          <round-button
            class="user-form__btn my-3"
            icon="fa-pencil-square-o"
            fullWidth
            @click="onEdit"
            data-testid="edit"
          >
            {{ $t('button.edit') }}
          </round-button>
          <template v-if="admin">
            <round-button
              class="user-form__btn my-3"
              :icon="model.activated ? 'fa-minus-circle' : 'fa-plus-circle'"
              fullWidth
              @click="onToggleActivated"
              data-testid="toggle-activated"
            >
              {{ model.activated ? $t('button.deactivate') : $t('button.activate') }}
            </round-button>
          </template>
          <template v-if="admin && model.blocked">
            <round-button
              class="user-form__btn my-3"
              icon="fa-plus-circle"
              fullWidth
              @click="onUnblock"
              data-testid="unblock"
            >
              {{ $t('button.unblock') }}
            </round-button>
          </template>
          <template v-if="admin && !model.blocked && model.activated && !isAdminUserDisplayed">
            <round-button
              class="user-form__btn my-3"
              fullWidth
              @click="onLoginAsUser"
              data-testid="login-as-user"
            >
              {{ $t('button.login_as_user') }}
            </round-button>
          </template>
        </template>
      </div>
    </div>
  </div>
</template>

<i18n lang="yaml">
ja:
  field:
    organization: '組織'
    name: 'ユーザー名'
    email: 'E-mail'
    password: '新しいパスワード'
    password_confirm: '新しいパスワード（確認）'
    locale: '言語'
    allowed_sensor_types: '利用可能なセンサー'
    plan: 'プラン'
    mirakun_user_id: 'mirakun.comのID'
    invited_by: '共有元'

  label:
    confirmation: '確認'
    create_account: 'アカウント作成'

  msg:
    deactivate_confirm: 'このアカウントの利用を停止します、よろしいですか？'
    update_user_success: 'アカウント情報を更新しました。'
    update_user_error: 'アカウント情報を更新しようとして、エラーが発生しました。'
    create_user_success: 'アカウントを作成しました。'
    create_user_error: 'アカウントを作成しようとして、エラーが発生しました。'
    email_already_exists: '指定されたメールアドレスはすでに登録されています。'

  button:
    save: '保存'
    cancel: 'キャンセル'
    activate: 'アカウント有効化'
    deactivate: 'アカウント無効化'
    unblock: 'ブロックを解除'
    edit: '編集'
    no: 'いいえ'
    yes: 'はい'
    login_as_user: '代理ログイン'

  plans:
    message: '{plan}は{switch_date}まで有効です。'
    date_format: 'YYYY年MM月DD日'

en:
  field:
    organization: 'Organization'
    name: 'Name'
    email: 'E-mail'
    password: 'New password'
    password_confirm: 'New password (confirm)'
    locale: 'Language'
    allowed_sensor_types: 'Allowed Sensor Types'
    plan: 'Plan'
    mirakun_user_id: 'mirakun.com User ID'
    invited_by: 'Invited by'

  label:
    confirmation: 'Confirmation'
    create_account: 'Create Account'

  msg:
    deactivate_confirm: 'This account will be deactivated. Are you sure?'
    update_user_success: 'Account information was successfully updated.'
    update_user_error: 'An error occurred while updating the account information.'
    create_user_success: 'Account was successfully created.'
    create_user_error: 'An error occurred while creating the account.'
    email_already_exists: 'This email address is already registered.'

  button:
    save: 'Save'
    cancel: 'Cancel'
    activate: 'Activate account'
    deactivate: 'Deactivate account'
    unblock: 'Unblock account'
    edit: 'Edit'
    no: 'No'
    yes: 'Yes'
    login_as_user: 'Login as User'

  plans:
    message: '{plan} is active until {switch_date}.'
    date_format: 'YYYY-MM-DD'
</i18n>

<script>
import _ from 'lodash';
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';
import Features from '@/mixins/features';
import Locales from '@/mixins/locales';
import Notifications from '@/mixins/notifications';
import PlanOptions from '@/mixins/planOptions';
import SensorTypes from '@/mixins/sensorTypes';

import EntityHeader from '@/components/atoms/EntityHeader';
import PageTitleArea from '@/components/atoms/PageTitleArea';
import RoundButton from '@/components/atoms/RoundButton';
import FormGroup from './Form/FormGroup';
import TextField from './Form/TextField';
import SelectField from './Form/SelectField';
import Validations from './Form/mixins/form-validations';
import Checkbox from './Form/Checkbox';

const DEFAULT_MODEL = {
  owner_id: null,
  name: '',
  email: '',
  locale: '',
  allowed_sensor_types: [],
  next_plan: '',
  mirakun_user_id: '',
  password: '',
  password_confirm: '',
  activated: false,
  blocked: false,
};

const buildModel = user => ({
  ...DEFAULT_MODEL,
  ..._.pick(user, Object.keys(DEFAULT_MODEL)),
});

export default {
  name: 'user-form',
  mixins: [Features, Locales, Notifications, PlanOptions, Validations, SensorTypes],
  props: {
    admin: {
      type: Boolean,
      default: false,
    },
    newRecord: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Checkbox,
    EntityHeader,
    FormGroup,
    PageTitleArea,
    RoundButton,
    SelectField,
    TextField,
  },
  inject: ['assumeUserIdentity'],
  async beforeMount() {
    if (this.admin) {
      if (!this.newRecord) {
        await this.requestUser(this.$route.params.email);
      }
      await this.requestOrganizations();
    } else {
      await this.getUserInfo();
      const user = this.getCurrentUser;
      await this.requestOrganization(user.owner_id);
    }
  },
  data() {
    return {
      show: false,
      valid: false,
      model: buildModel({}),
      state: {
        editing: this.newRecord,
      },
      rules: {
        password_required: this.newRecord ? this.required(this.$t('field.password')) : true,
      },
    };
  },
  computed: {
    ...mapGetters([
      'getUser',
      'getCurrentUser',
      'getOrganization',
    ]),
    isNormalUser() {
      return !(this.newRecord || this.admin);
    },
    isAdminUserDisplayed() {
      return this.user.permissions.includes('admin');
    },
    user() {
      if (this.newRecord) {
        this.show = true;
        return {};
      } else if (this.admin) {
        return this.getUser(this.$route.params.email);
      }
      return this.getCurrentUser;
    },
    sensorTypesForUser() {
      return [this.SENSOR_TYPES.STATION];
    },
    stationEnabled() {
      return this.model.allowed_sensor_types.includes(this.SENSOR_TYPES.STATION);
    },
    planChangedAlertMessage() {
      const planWasUnselected = !this.user.current_plan || this.user.current_plan === '99';

      if (!planWasUnselected && this.model.next_plan !== this.user.current_plan) {
        return this.$t(
          'plans.message',
          {
            plan: this.getPlanAdminLabel(this.user.current_plan),
            switch_date: this.plan_switch_date,
          },
        );
      }

      return null;
    },
    plan_switch_date() {
      return moment().endOf('month').format(this.$t('plans.date_format'));
    },
    mirakunDeviceEnabled() {
      return this.model.allowed_sensor_types.includes(this.SENSOR_TYPES.MIRAKUN_DEVICE);
    },
    organization() {
      const user = this.getCurrentUser;
      return this.getOrganization(user.owner_id) || {};
    },
    organization_options() {
      const organizations = this.$store.getters.getOrganizations;
      return [
        { text: '', value: '' },
        ...organizations.map((org) => ({
          text: org.name,
          value: org.id,
        })),
      ];
    },
  },
  methods: {
    ...mapActions([
      'createUser',
      'getUserInfo',
      'updateUser',
      'updateUserInfo',
      'requestUser',
      'requestOrganization',
      'requestOrganizations',
    ]),
    onLoginAsUser: async function () {
      await this.assumeUserIdentity(this.user);
      this.$router.push('/');
    },
    handleCreateError: async function (operation) {
      try {
        await operation();
      } catch (error) {
        if (this.isAlreadyExistsError(error)) {
          this.notifyError('msg.email_already_exists');
          return;
        }
        this.notifyError('msg.create_user_error');
      }
    },
    handleUpdateError: async function (operation) {
      try {
        await operation();
      } catch (error) {
        if (this.isAlreadyExistsError(error)) {
          this.notifyError('msg.email_already_exists');
          return;
        }
        this.notifyError('msg.update_user_error');
      }
    },
    isAlreadyExistsError(error) {
      return error.response.data.type === 'resource_already_exists';
    },
    onCancel() {
      if (this.newRecord) {
        this.$router.push('/admin/accounts');
      }
      this.model = buildModel(this.user);
      this.state.editing = false;
    },
    onEdit() {
      this.state.editing = true;
    },
    onSave: async function () {
      if (this.$refs.form.validate()) {
        const params = this.pickParams();
        if (this.admin) {
          if (this.newRecord) {
            this.handleCreateError(async () => {
              await this.createUser(params);
              this.notifySuccess('msg.create_user_success');
              this.$router.push(`/admin/accounts/${params.email}`);
              // Because component doesn't change it will not recreate data
              // So we need to reset this manually
              this.rules.password_required = true;
              this.state.editing = false;
            });
          } else {
            this.handleUpdateError(async () => {
              await this.updateUser({ email: this.user.email, attributes: params });
              this.notifySuccess('msg.update_user_success');
              this.state.editing = false;
            });
          }
        } else {
          this.handleUpdateError(async () => {
            await this.updateUserInfo(params);
            this.notifySuccess('msg.update_user_success');
            this.state.editing = false;
          });
        }
      }
    },
    onToggleActivated: async function () {
      this.handleUpdateError(async () => {
        await this.updateUser({
          email: this.user.email,
          attributes: { activated: !this.model.activated },
        });
        this.notifySuccess('msg.update_user_success');
      });
    },
    onUnblock: async function () {
      this.handleUpdateError(async () => {
        await this.updateUser({ email: this.user.email, attributes: { blocked: false } });
        this.notifySuccess('msg.update_user_success');
      });
    },
    pickParams() {
      const allowedKeys = this._.keys(DEFAULT_MODEL);
      const result = { ...this.model };

      delete result.activated;
      delete result.blocked;

      if (!result.password) {
        delete result.password;
      }
      delete result.password_confirm;

      if (this.admin) {
        if (!this.stationEnabled) {
          delete result.next_plan;
        }

        if (result.owner_id === '') {
          result.owner_id = null;
        }
      } else {
        delete result.allowed_sensor_types;
        delete result.next_plan;
      }
      if (!this.mirakunDeviceEnabled) {
        delete result.mirakun_user_id;
      }

      return this._.pickBy(result, (value, key) => (
        allowedKeys.includes(key) && value !== this.user[key]
      ));
    },
  },
  watch: {
    user(val) {
      this.model = buildModel(val);
      this.show = !!val;
    },
  },
  filters: {
    invitedBy(value) {
      if (value) {
        const { name, organization_name: organizationName } = value;
        return `${organizationName || ''} (${name})`;
      }
      return '';
    },
  },
};
</script>

<style scoped lang="sass">
@import 'vuetify/src/styles/styles.sass'

.user-form
  &__field
    display: flex

    &__icon
      font-size: 16px

  &__label,
  &__input-group
    padding-top: 4px
    padding-bottom: 4px

  &__label
    border-right: 1px solid
    padding-right: 16px
    width: 35%

  &__input-group
    flex: 1
    padding-left: 16px

    ::v-deep .input-group
      margin-top: -6px
      margin-bottom: -6px
      padding: 0 0 16px

    ::v-deep .input-group--text-field .input-group__input
      background-color: transparent
      border: 1px solid
      padding: 0 4px

      input
        width: 100%

    &--checkbox
      color: map-get($colors, 'grey', 'base') !important

      ::v-deep .input-group--selection-controls__ripple:before
        background-color: transparent !important

  &__btn-group
    margin: 24px 0

@media #{map-get($display-breakpoints, 'md-and-up')}
  .user-form
    &__content
      display: flex

    &__form
      width: 50%

    &__label,
    &__input-group
      padding: 20px 16px

    &__input-group ::v-deep .input-group
      padding: 0

    &__label
      border-right: 0

    &__btn-group
      margin: 0 40px
      width: 240px

    &__btn ::v-deep .v-btn__content
      padding: 0 32px
</style>
