<template>
  <round-button icon="file_download" :loading="exporting" @click="downloadCsv">
    {{ $t('button.export') }}
  </round-button>
</template>

<i18n lang="yaml">
ja:
  button:
    export: 'CSV出力'
  msg:
    export:
      failed: CSVの出力に失敗しました。

en:
  button:
    export: 'Export CSV'
  msg:
    export:
      failed: 'Failed to output CSV.'
</i18n>

<script>
import { exportCsv } from '@/libs/csv';
import CsvExport from '@/mixins/csvExport';
import Notifications from '@/mixins/notifications';
import EventContainer from '@/mixins/eventContainer';
import RoundButton from '@/components/atoms/RoundButton';

export default {
  name: 'SensorEventsCsvExportButton',
  mixins: [CsvExport, EventContainer, Notifications],
  components: {
    RoundButton,
  },
  props: {
    // for EventContainer
    sensor: {
      type: Object,
      required: true,
    },
    requestParams: {
      type: Object,
      required: true,
    },
  },
  computed: {
    sensorId() {
      return this.sensor.id;
    },
  },
  data() {
    return {
      exporting: false,
    };
  },
  methods: {
    downloadCsv: async function () {
      this.exporting = true;
      try {
        await this.loadAllEvents();
        exportCsv(this.generateCsv(this.sensor, this.events), `${this.sensor.name}.csv`);
      } catch (error) {
        this.notifyError('msg.export.failed');
        throw error;
      } finally {
        this.clearEvents();
        this.exporting = false;
      }
    },
  },
};
</script>
