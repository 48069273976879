var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "event-detail-header" }, [
    _c("span", { staticClass: "event-detail-header__date" }, [
      _c("span", [_vm._v(_vm._s(_vm.timestamp.date))]),
      _c("span", { staticClass: "ml-3" }, [_vm._v(_vm._s(_vm.timestamp.time))]),
    ]),
    _vm._v(" "),
    _vm.count
      ? _c("span", { staticClass: "event-detail-header__count" }, [
          _vm._v(_vm._s(_vm.count)),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }