var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.visible
    ? _c(
        "transition",
        { staticClass: "navigation", attrs: { name: "toggle-nav" } },
        [
          _c(
            "div",
            { staticClass: "navigation__panel" },
            [
              _c(
                "v-list",
                [
                  _vm._l(_vm.items, function (item) {
                    return _c(
                      "v-list-item",
                      {
                        key: item.title,
                        staticClass: "navigation__item",
                        attrs: { to: item.path },
                        on: {
                          click: function ($event) {
                            return _vm.closeNav()
                          },
                        },
                      },
                      [
                        _c(
                          "v-list-item-content",
                          {
                            attrs: {
                              "data-testid": "navigation__item--" + item.path,
                            },
                          },
                          [
                            _vm._v(
                              "\n          " + _vm._s(item.title) + "\n        "
                            ),
                          ]
                        ),
                      ],
                      1
                    )
                  }),
                  _vm._v(" "),
                  _c("v-divider"),
                  _vm._v(" "),
                  _c("v-subheader", {
                    staticClass: "primary--text",
                    domProps: { textContent: _vm._s(_vm.userName) },
                  }),
                  _vm._v(" "),
                  _c(
                    "v-list-item",
                    {
                      staticClass: "navigation__item",
                      attrs: { to: _vm.accountPath },
                      on: {
                        click: function ($event) {
                          return _vm.closeNav()
                        },
                      },
                    },
                    [
                      _c("v-list-item-title", [
                        _vm._v(_vm._s(_vm.accountLabel)),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.isPartOfOrganization
                    ? _c(
                        "v-list-item",
                        {
                          staticClass: "navigation__item",
                          attrs: { to: _vm.organizationPath },
                          on: {
                            click: function ($event) {
                              return _vm.closeNav()
                            },
                          },
                        },
                        [
                          _c("v-list-item-title", [
                            _vm._v(_vm._s(_vm.organizationLabel)),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "v-list-item",
                    {
                      staticClass: "navigation__item navigation__logout",
                      attrs: { "data-testid": "navigation__logout" },
                      on: {
                        click: function ($event) {
                          return _vm.signOut()
                        },
                      },
                    },
                    [
                      _c("v-list-item-title", [
                        _vm._v(_vm._s(_vm.logoutLabel)),
                      ]),
                    ],
                    1
                  ),
                ],
                2
              ),
            ],
            1
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }