<template>
  <div ref="detector" />
</template>

<script>
export default {
  name: 'visible-in-screen-detector',
  data() {
    return {
      scrollListener: this._.throttle(this.checkVisibility, 1000 / 60),
      wasVisible: false,
    };
  },
  mounted() {
    window.addEventListener('scroll', this.scrollListener);
    this.checkVisibility();
  },
  destroyed() {
    window.removeEventListener('scroll', this.scrollListener);
  },
  methods: {
    checkVisibility() {
      const visible = this.isVisibleInScreen();
      if (visible !== this.wasVisible) {
        this.$emit('visibility-changed', visible);
        this.wasVisible = visible;
      }
    },
    isVisibleInScreen() {
      const rect = this.$refs.detector.getBoundingClientRect();
      const html = document.documentElement;
      return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || html.clientHeight) &&
        rect.right <= (window.innerWidth || html.clientWidth)
      );
    },
  },
};
</script>
