var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { "data-testid": "installation_site_events" } },
    [
      _c(
        "v-row",
        { staticClass: "my-3", attrs: { align: "center" } },
        [
          _c(
            "v-btn",
            { attrs: { height: "56px", text: "", to: _vm.linkTarget } },
            [
              _c(
                "v-row",
                { attrs: { align: "center" } },
                [
                  _c("icon", {
                    staticClass: "mr-2",
                    attrs: { type: _vm.installationSite.type },
                  }),
                  _vm._v(" "),
                  _c(
                    "h2",
                    {
                      staticClass: "title primary--text",
                      attrs: { "data-testid": "installation_site_name" },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.installationSite.name) +
                          "\n        "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("v-spacer"),
          _vm._v(" "),
          _vm.installationSite.type === _vm.SENSOR_TYPES.RAT
            ? _c("rat-chart-button", {
                staticClass: "hidden-sm-and-down",
                attrs: { eventLoader: _vm.eventLoader },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "mb-4" },
        [
          _c(
            "round-button",
            {
              attrs: {
                icon: _vm.expanded
                  ? "fa-chevron-circle-up"
                  : "fa-chevron-circle-down",
                "data-testid": "expand",
              },
              on: { click: _vm.toggleExpanded },
            },
            [_vm._v("\n      " + _vm._s(_vm.$t("expand_button")) + "\n    ")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        { attrs: { wrap: "" } },
        [
          _vm._l(_vm.displayedEvents, function (event) {
            return [
              _c(
                "v-flex",
                {
                  key: event.id,
                  staticClass: "pa-2 event-wrapper",
                  attrs: { md3: "", xs12: "" },
                },
                [
                  _c("event", {
                    staticClass: "event",
                    attrs: { event: event, editable: false },
                    on: {
                      "event:selected": function ($event) {
                        return _vm.showEvent(event)
                      },
                    },
                  }),
                ],
                1
              ),
            ]
          }),
        ],
        2
      ),
      _vm._v(" "),
      _vm.expanded
        ? _c(
            "div",
            { staticClass: "mt-4" },
            [
              _c("visible-in-screen-detector", {
                on: { "visibility-changed": _vm.listEndVisibilityChanged },
              }),
              _vm._v(" "),
              _vm.eventLoader.loading
                ? _c("v-progress-linear", {
                    attrs: {
                      color: "primary",
                      indeterminate: "",
                      rounded: "",
                      height: "6",
                    },
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("gallery-view", {
        attrs: {
          visible: _vm.galleryView.visible,
          installationSite: _vm.installationSite,
          eventLoader: _vm.eventLoader,
        },
        on: {
          close: function ($event) {
            _vm.galleryView.visible = false
          },
        },
        model: {
          value: _vm.galleryView.index,
          callback: function ($$v) {
            _vm.$set(_vm.galleryView, "index", $$v)
          },
          expression: "galleryView.index",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }