var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "gallery-view gallery-view--insect-v2" },
    [
      _c(
        "gallery-view-toolbar",
        {
          attrs: {
            editable: _vm.editable,
            event: _vm.event,
            sensor: _vm.sensor,
            canDownload: _vm.canDownload,
            title: _vm.title,
          },
          on: {
            download: _vm.download,
            close: function ($event) {
              return _vm.$emit("close")
            },
          },
        },
        [
          _vm.editable
            ? _c(
                "div",
                { staticClass: "gallery-view__actions" },
                [
                  !_vm.event.updating
                    ? _c(
                        "toolbar-button",
                        {
                          attrs: {
                            icon: "fa-edit",
                            disabled: !_vm.canEditDetections,
                            "data-testid": "edit",
                          },
                          nativeOn: {
                            click: function ($event) {
                              $event.stopPropagation()
                              _vm.editing = true
                              _vm.pickedDetectionType = "all"
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n        " +
                              _vm._s(_vm.$t("menu.edit")) +
                              "\n      "
                          ),
                        ]
                      )
                    : _c(
                        "toolbar-button",
                        { attrs: { icon: "fa-refresh", disabled: "" } },
                        [
                          _vm._v(
                            "\n        " +
                              _vm._s(_vm.$t("menu.updating")) +
                              "\n      "
                          ),
                        ]
                      ),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
      _vm._v(" "),
      _c(
        "layout",
        {
          attrs: { hasPrevious: _vm.hasPrevious, hasNext: _vm.hasNext },
          on: {
            previous: function ($event) {
              return _vm.$emit("previous")
            },
            next: function ($event) {
              return _vm.$emit("next")
            },
          },
          scopedSlots: _vm._u([
            {
              key: "top",
              fn: function () {
                return [
                  _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [
                      _c(
                        "v-col",
                        [
                          _c("tag-display", {
                            attrs: { editable: _vm.editable, event: _vm.event },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        { attrs: { cols: "auto" } },
                        [
                          _c("gallery-view-fullscreen-enter", {
                            model: {
                              value: _vm.fullscreen,
                              callback: function ($$v) {
                                _vm.fullscreen = $$v
                              },
                              expression: "fullscreen",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
            {
              key: "side",
              fn: function () {
                return [
                  _c(
                    "div",
                    { staticClass: "result-table pa-2" },
                    [
                      _c("v-checkbox", {
                        staticClass: "pa-0 mb-3",
                        attrs: {
                          label: _vm.$t("field.original"),
                          "hide-details": "",
                          color: "primary",
                        },
                        model: {
                          value: _vm.viewOriginal,
                          callback: function ($$v) {
                            _vm.viewOriginal = $$v
                          },
                          expression: "viewOriginal",
                        },
                      }),
                      _vm._v(" "),
                      _c("insect-count-table", {
                        attrs: {
                          insectCounts: _vm.event.insect_counts,
                          showRadiobutton:
                            _vm.canEditDetections && !_vm.imageLoading,
                          pickedDetectionType: _vm.pickedDetectionType,
                        },
                        on: {
                          "update:pickedDetectionType": function ($event) {
                            _vm.pickedDetectionType = $event
                          },
                          "update:picked-detection-type": function ($event) {
                            _vm.pickedDetectionType = $event
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _vm._v(" "),
          _c(
            "div",
            [
              _c(
                "fullscreen",
                {
                  model: {
                    value: _vm.fullscreen,
                    callback: function ($$v) {
                      _vm.fullscreen = $$v
                    },
                    expression: "fullscreen",
                  },
                },
                [
                  _c(
                    "v-carousel",
                    {
                      attrs: { touchless: "", height: null },
                      model: {
                        value: _vm.index,
                        callback: function ($$v) {
                          _vm.index = $$v
                        },
                        expression: "index",
                      },
                    },
                    _vm._l(_vm.images, function (image) {
                      return _c(
                        "v-carousel-item",
                        { key: image.id, attrs: { eager: "" } },
                        [
                          _c(
                            "picked-insects-draw",
                            {
                              attrs: {
                                imageSrc: _vm.event.attachments[image],
                                pickedDetectionType: _vm.pickedDetectionType,
                                currentPhotoKey: _vm.editTarget,
                                event: _vm.event,
                                hideCanvas: _vm.hideCanvas,
                              },
                            },
                            [
                              _c("loading-image", {
                                attrs: {
                                  src: _vm.event.attachments[image],
                                  forceLoading: _vm.imageLoading,
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm._t("thumbnails"),
            ],
            2
          ),
        ]
      ),
      _vm._v(" "),
      _c("insect-detections-editor", {
        attrs: {
          event: _vm.event,
          imageSrc: _vm.event.attachments[_vm.editTarget],
          detectionsUrl: _vm.detectionsUrl,
          canViewDetectionDetail: "",
        },
        on: {
          updated: function ($event) {
            return _vm.$emit("pollEvent")
          },
        },
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function () {
              return [
                _c("content-header", {
                  staticClass: "w-100",
                  attrs: { title: _vm.title, titleClass: "title my-1" },
                  scopedSlots: _vm._u([
                    {
                      key: "icon",
                      fn: function () {
                        return [
                          _c("sensor-icon", { attrs: { sensor: _vm.sensor } }),
                        ]
                      },
                      proxy: true,
                    },
                    {
                      key: "subtitle",
                      fn: function () {
                        return [
                          _c(
                            "div",
                            { staticClass: "overflow-ellipsis title details" },
                            [
                              _vm._v(
                                "\n            " + _vm._s(_vm.timestamp.date)
                              ),
                              _c("span", { staticClass: "ml-3" }),
                              _vm._v(
                                _vm._s(
                                  _vm.timestamp.time + " " + _vm.event.count
                                ) + "\n          "
                              ),
                            ]
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                }),
              ]
            },
            proxy: true,
          },
        ]),
        model: {
          value: _vm.editing,
          callback: function ($$v) {
            _vm.editing = $$v
          },
          expression: "editing",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }