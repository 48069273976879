<template>
  <v-footer id="bottom-navigation">
    <div class="logo hidden-sm-and-down">
      <img src="../assets/logo.png" width="134">
    </div>
    <div class="footer-info mr-0">
      <ul class="hidden-sm-and-down navigation text-right mb-4">
        <li v-for="item in items" :key="item.title">
          <v-btn text :to="item.path">{{ item.title }}</v-btn>
        </li>
      </ul>
      <copyright-footer class="text-center text-md-right"/>
    </div>
  </v-footer>
</template>

<script>
import Navigation from '@/mixins/navigation';
import CopyrightFooter from './CopyrightFooter';

export default {
  name: 'bottom-navigation',
  mixins: [Navigation],
  components: {
    CopyrightFooter,
  },
};
</script>

<style scoped lang="sass">
@import 'vuetify/src/styles/styles.sass'
#bottom-navigation
  background-color: white
  height: 120px
  margin-top: -80px
  padding-left: $container-padding-x
  padding-right: $container-padding-x

  .logo
    font-size: 0

  ul
    margin-right: calc(-#{$container-padding-x})
  li
    .btn
      margin: 0
    & + li:before
      content: '|'
      margin-right: 3px

  .footer-info
    margin: 0
    width: 100%

  .logo, li
    display: inline-block
    vertical-align: middle

  @media (min-width: 1024px)
    height: 200px
    margin-top: -200px
    padding-left: calc(#{$container-padding-x} * 5)
    padding-right: calc(#{$container-padding-x} * 5)

    .footer-info
      display: inline-block
      margin-left: -140px
      padding-left: 140px
</style>
